import React, { useEffect, useState, useRef, useCallback } from 'react';
import Spinner from './Spinner';
import api from '../services/api';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from './ui/table';
import { Button } from './ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from './ui/dialog';
import { Input } from './ui/input';
import { Textarea } from './ui/textarea';
import { Badge } from './ui/badge';
import { forceUtcThenConvertToMontreal } from '../lib/dateUtils';
import { toast } from 'react-toastify';
import GradeLogModal from './GradeLogModal';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Tooltip, TooltipProvider, TooltipTrigger } from './ui/tooltip';

const CONFIDENCE_THRESHOLD_GREEN = 0.95;
const CONFIDENCE_THRESHOLD_ORANGE = 0.90;

function computeTotalGrade(submission) {
  if (!submission.answers || !submission.answers.length) {
    return { totalEarned: 0, totalPossible: 0 };
  }
  let totalEarned = 0;
  let totalPossible = 0;
  submission.answers.forEach((ans) => {
    const mode = ans.grading_mode;
    const pts = ans.max_points || 0;
    if (mode === 'points') {
      totalEarned += ans.grade;
      totalPossible += pts;
    } else {
      totalEarned += ans.grade * pts;
      totalPossible += pts;
    }
  });
  return { totalEarned, totalPossible };
}

function ResizableHeader({ children, width, onResize }) {
  const [isResizing, setIsResizing] = useState(false);

  const startResize = useCallback(
    (e) => {
      e.preventDefault();
      setIsResizing(true);

      const startX = e.pageX;
      const startWidth = width;

      const onMouseMove = (e2) => {
        const newWidth = startWidth + (e2.pageX - startX);
        onResize(Math.max(50, newWidth));
      };
      const onMouseUp = () => {
        setIsResizing(false);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
    [width, onResize]
  );

  return (
    <TableHead
      style={{
        width: `${width}px`,
        position: 'relative',
        padding: '0.5rem',
      }}
      className="select-none"
    >
      <div className="flex items-center justify-between">
        <span>{children}</span>
        <div
          className={`
            absolute top-0 right-[-4px] h-full w-[8px] cursor-col-resize
            bg-border/40 hover:bg-primary/50 active:bg-primary
            ${isResizing ? 'bg-primary' : ''}
          `}
          onMouseDown={startResize}
          style={{ cursor: 'col-resize', zIndex: 10 }}
        />
      </div>
    </TableHead>
  );
}

/**
 * Determine overall submission status:
 *  - If all answers are locked => "locked"
 *  - Else if any answer is "grading" => "grading"
 *  - Else => "graded"
 */
function getSubmissionStatus(answers) {
  if (answers?.length && answers.every((a) => a.status === 'locked')) {
    return 'locked';
  }
  if (answers?.some((a) => a.status === 'failed')) {
    return 'failed';
  }
  if (answers?.some((a) => a.status === 'grading')) {
    return 'grading';
  }
  return 'graded';
}

/**
 * Render a small UI element for the entire submission status
 */
function renderSubmissionStatus(status) {
  if (status === 'grading') {
    return (
      <div className="flex items-center gap-1">
        <Spinner />
        <span className="text-xs">En cours</span>
      </div>
    );
  }
  if (status === 'failed') {
    return (
      <Tooltip content="Au moins une question a rencontré une erreur lors de la correction" delayDuration={100}>
        <TooltipTrigger>
          <Badge 
            variant="destructive" 
            className="bg-red-100 text-red-900 hover:bg-red-200 border border-red-300 dark:bg-red-900/30 dark:text-red-100 dark:hover:bg-red-900/40 dark:border-red-800"
          >
            Erreur
          </Badge>
        </TooltipTrigger>
      </Tooltip>
    );
  }
  if (status === 'locked') {
    return (
      <Badge variant="secondary" className="bg-blue-100 text-blue-900 hover:bg-blue-200 border border-blue-300 dark:bg-blue-900/30 dark:text-blue-100 dark:hover:bg-blue-900/40 dark:border-blue-800">
        Validé et verrouillé
      </Badge>
    );
  }
  // fallback => 'graded'
  return (
    <Badge variant="secondary" className="bg-green-100 text-green-900 hover:bg-green-200 border border-green-300 dark:bg-green-900/30 dark:text-green-100 dark:hover:bg-green-900/40 dark:border-green-800">
      Corrigé
    </Badge>
  );
}

/**
 * Return a small UI element for each answer's status:
 *  - "grading" => spinner+text "En cours"
 *  - "failed" => red badge "Erreur"
 *  - "locked" => "Validé et verrouillé"
 *  - else => "graded" => "Corrigé"
 *
 * But we also want to possibly show a "Valider et verrouiller" button
 * if confidence >= 0.95 and status is "graded".
 *
 * We'll take subIndex and handleConfirmConfidence to properly call it.
 */
function renderAnswerStatus(answer, subIndex, onConfirmConfidence, onUnlock) {
  if (answer.status === 'grading') {
    return (
      <div className="flex items-center gap-1">
        <Spinner />
        <span className="text-xs">En cours</span>
      </div>
    );
  }

  // Add handling for the new 'failed' status
  if (answer.status === 'failed') {
    return (
      <Tooltip content="Une erreur s'est produite lors de la correction" delayDuration={100}>
        <TooltipTrigger>
          <Badge 
            variant="destructive" 
            className="bg-red-100 text-red-900 hover:bg-red-200 border border-red-300 dark:bg-red-900/30 dark:text-red-100 dark:hover:bg-red-900/40 dark:border-red-800"
          >
            Erreur
          </Badge>
        </TooltipTrigger>
      </Tooltip>
    );
  }

  if (answer.status === 'locked') {
    return (
      <div className="flex flex-col items-center gap-1">
        <Badge 
          variant="secondary" 
          className="bg-blue-100 text-blue-900 hover:bg-blue-200 cursor-pointer border border-blue-300 dark:bg-blue-900/30 dark:text-blue-100 dark:hover:bg-blue-900/40 dark:border-blue-800"
          onClick={() => onUnlock(subIndex, answer.id)}
          title="Une réponse verrouillée ne sera pas re-corrigée lors des futures corrections automatiques"
        >
          Validé et verrouillé
        </Badge>
      </div>
    );
  }

  // "graded"
  return (
    <div className="flex flex-col items-center gap-1">
      <Badge variant="secondary" className="bg-green-100 text-green-900 hover:bg-green-200 border border-green-300 dark:bg-green-900/30 dark:text-green-100 dark:hover:bg-green-900/40 dark:border-green-800">
        Corrigé
      </Badge>
      {answer.degree_of_confidence >= CONFIDENCE_THRESHOLD_GREEN && (
        <Button
          variant="outline"
          size="sm"
          className="mt-1 whitespace-normal h-auto py-1 text-xs"
          onClick={() => onConfirmConfidence(subIndex, answer.id)}
        >
          Valider et verrouiller
        </Button>
      )}
    </div>
  );
}

/**
 * Round a number to the nearest 0.5
 */
function roundToHalf(value) {
  // First convert to number and handle invalid input
  let num = parseFloat(value);
  if (isNaN(num)) return 0;
  
  // Round to nearest 0.5
  return Math.round(num * 2) / 2;
}

function SubmissionListWrapper({ assignmentId, onSubmissionsLoaded }) {
  return (
    <TooltipProvider>
      <SubmissionListContent assignmentId={assignmentId} onSubmissionsLoaded={onSubmissionsLoaded} />
    </TooltipProvider>
  );
}

function SubmissionListContent({ assignmentId, onSubmissionsLoaded }) {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [viewMode, setViewMode] = useState('student'); // 'student' or 'question'

  const [questionMap, setQuestionMap] = useState({});
  const [editingCorrectAnswer, setEditingCorrectAnswer] = useState({});
  const intervalRef = useRef(null);

  // For local grade edits (un-saved)
  // Key is answerId, value is the new grade in string form
  const [editedGrades, setEditedGrades] = useState({});

  // For showing the confirm re-grade modal
  const [showRegradeModal, setShowRegradeModal] = useState(false);
  const [currentEditQuestionNumber, setCurrentEditQuestionNumber] = useState(null);
  const [currentEditNewValue, setCurrentEditNewValue] = useState('');

  const [columnWidths, setColumnWidths] = useState({
    questionNumber: 64,
    question: 180,
    correct: 180,
    answer: 280, // Significantly increased width for answers
    grade: 100, 
    justification: 400, // Increased width for justification
    confidence: 120, // Increased
    status: 50, // Increased
    student: 100, // Significantly increased width for student names
    email: 180, // Kept for student view
  });

  // Track if regrading is in progress
  const [regrading, setRegrading] = useState(false);

  // Add state for grade log modal
  const [gradeLogModal, setGradeLogModal] = useState(null);

  // Add state for regrade dialog
  const [regradeDialogOpen, setRegradeDialogOpen] = useState(false);
  
  // For question view, track expanded questions
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  
  // Add state for bulk locking
  const [bulkLocking, setBulkLocking] = useState(false);

  useEffect(() => {
    if (assignmentId) {
      fetchSubmissions(assignmentId);
      fetchQuestions(assignmentId);

      intervalRef.current = setInterval(() => {
        fetchSubmissions(assignmentId, true);
      }, 5000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [assignmentId]);

  async function fetchSubmissions(id, silent = false) {
    if (!silent) {
      setLoading(true);
      setError('');
    }
    try {
      const data = await api.getSubmissionsForAssignment(id);
      setSubmissions(data);
      
      // Notify parent component about the submissions count
      if (onSubmissionsLoaded && typeof onSubmissionsLoaded === 'function') {
        onSubmissionsLoaded(data.length);
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
      
      // Notify parent component about error (0 submissions)
      if (onSubmissionsLoaded && typeof onSubmissionsLoaded === 'function') {
        onSubmissionsLoaded(0);
      }
    } finally {
      if (!silent) {
        setLoading(false);
      }
    }
  }
  
  // Handle bulk locking of all answers
  const handleBulkLock = async () => {
    if (!submissions || submissions.length === 0) return;
    
    setBulkLocking(true);
    
    try {
      // Collect all unlocked answers
      let lockOperations = [];
      
      for (let subIndex = 0; subIndex < submissions.length; subIndex++) {
        const sub = submissions[subIndex];
        
        for (const answer of sub.answers || []) {
          // Skip already locked answers
          if (answer.status === 'locked') continue;
          
          lockOperations.push({
            subIndex,
            answerId: answer.id,
            grade: answer.grade,
          });
        }
      }
      
      // Optimistic UI update - set all answers to locked in state
      setSubmissions((prevSubs) => {
        const newSubs = [...prevSubs].map(sub => {
          const newAnswers = (sub.answers || []).map(answer => {
            if (answer.status !== 'locked') {
              return {
                ...answer,
                status: 'locked',
                degree_of_confidence: 1.0
              };
            }
            return answer;
          });
          
          return {
            ...sub,
            answers: newAnswers
          };
        });
        
        return newSubs;
      });
      
      // Perform all API calls
      const results = await Promise.allSettled(
        lockOperations.map(op => 
          api.updateAnswer(
            submissions[op.subIndex].assignment_id,
            submissions[op.subIndex].student_email,
            op.answerId,
            { 
              grade: op.grade,
              status: 'locked'
            }
          )
        )
      );
      
      // Check for errors
      const failures = results.filter(r => r.status === 'rejected');
      if (failures.length > 0) {
        console.error(`${failures.length} operations failed:`, failures);
        toast.warning(`${failures.length} réponses n'ont pas pu être verrouillées.`);
      } else {
        toast.success('Toutes les réponses ont été verrouillées avec succès.');
      }
      
      // Refresh submissions to ensure state is in sync
      fetchSubmissions(assignmentId, true);
      
    } catch (err) {
      console.error('Error bulk locking answers:', err);
      toast.error(`Erreur: ${err.message}`);
      
      // Refresh to get back to consistent state
      fetchSubmissions(assignmentId);
    } finally {
      setBulkLocking(false);
    }
  };

  async function fetchQuestions(id) {
    try {
      const data = await api.getAssignmentQuestions(id);
      const map = {};
      data.forEach((q) => {
        map[q.question_number] = q;
      });
      setQuestionMap(map);
    } catch (err) {
      console.error('Error fetching assignment questions:', err);
    }
  }

  function toggleExpand(assignId, email) {
    const key = `${assignId}_${email}`;
    setExpandedKeys((prev) =>
      prev.includes(key) ? prev.filter((x) => x !== key) : [...prev, key]
    );
  }
  
  function toggleExpandQuestion(questionNumber) {
    setExpandedQuestions((prev) =>
      prev.includes(questionNumber) ? prev.filter((x) => x !== questionNumber) : [...prev, questionNumber]
    );
  }

  //
  // Called whenever the user changes the input for grade (LOCALLY).
  // We'll only call the backend once user clicks "Sauvegarder"
  //
  const handleGradeChangeLocal = (answerId, newValue) => {
    // Round to nearest 0.5
    const roundedValue = roundToHalf(newValue);
    
    setEditedGrades((prev) => ({
      ...prev,
      [answerId]: roundedValue,
    }));
  };

  //
  // Let user save a changed grade. We'll open the explanation modal first,
  // and only call the backend after user provides or skips the explanation.
  //
  const handleSaveGrade = async (subIndex, answerId) => {
    const submission = submissions[subIndex];
    const answer = submission.answers.find((a) => a.id === answerId);
    if (!answer) return;

    const newGrade = editedGrades[answerId];
    if (newGrade === undefined) return;

    // First, just show the modal for explanation
    // We'll save both the grade and explanation in a single API call later
    
    // Get question_id from questionMap using the answer's question_number
    const questionNumber = answer.question_number;
    const questionId = questionMap[questionNumber]?.id;
    
    console.log("Setting modal with:", {
      answerId, 
      questionNumber,
      questionId,
      questionMap
    });
    
    setGradeLogModal({
      assignmentId,
      studentEmail: submission.student_email,
      answerId,
      grade: parseFloat(newGrade),
      questionId,
      questionNumber, // Adding this for lookup if needed
      // Save the subIndex and answerIndex so we can update the UI later
      subIndex,
      answerIndex: submission.answers.findIndex((a) => a.id === answerId),
      // Add additional info for the modal
      question: answer.question,
      answer: answer.answer,
      correct_answer: questionMap[questionNumber]?.correct_answer || '',
      old_grade: answer.grade
    });
  };
  
  // Function to update UI after grade update is completed
  const handleUpdateComplete = (subIndex, answerIndex, updatedAnswer) => {
    // Update local state with the new answer data
    setSubmissions(prevSubmissions => {
      const newSubmissions = [...prevSubmissions];
      if (newSubmissions[subIndex] && newSubmissions[subIndex].answers) {
        newSubmissions[subIndex].answers[answerIndex] = updatedAnswer;
      }
      return newSubmissions;
    });
    
    // Clear the edited grade
    setEditedGrades(prev => {
      const next = { ...prev };
      delete next[updatedAnswer.id];
      return next;
    });
  };

  //
  // Let user confirm an existing grade => set confidence=100% if user is happy
  //
  const handleConfirmConfidence = async (subIndex, answerId) => {
    const sub = submissions[subIndex];
    const oldSubmission = sub;
    const oldAnswers = oldSubmission.answers || [];
    const foundIndex = oldAnswers.findIndex((a) => a.id === answerId);
    if (foundIndex === -1) return;

    const oldAnswer = oldAnswers[foundIndex];
    const oldGrade = oldAnswer.grade;
    const oldConfidence = oldAnswer.degree_of_confidence;

    // Optimistic UI: set local confidence to 1.0 and status to 'locked'
    setSubmissions((prevSubs) => {
      const newSubs = [...prevSubs];
      const currentSub = { ...newSubs[subIndex] };
      const newAnswers = [...currentSub.answers];
      const updatedAnswer = { ...newAnswers[foundIndex] };

      updatedAnswer.degree_of_confidence = 1.0; // confirm
      updatedAnswer.status = 'locked'; // immediately update status to locked
      newAnswers[foundIndex] = updatedAnswer;
      currentSub.answers = newAnswers;
      newSubs[subIndex] = currentSub;
      return newSubs;
    });

    try {
      // Call the same updateAnswer endpoint with the same grade and set status to locked
      await api.updateAnswer(
        sub.assignment_id,
        sub.student_email,
        answerId,
        { 
          grade: oldGrade,
          status: 'locked'
        }
      );
    } catch (err) {
      console.error(err);
      toast.error(`Erreur lors de la confirmation: ${err.message}`);
      // revert
      setSubmissions((prevSubs) => {
        const newSubs = [...prevSubs];
        const revertSub = { ...newSubs[subIndex] };
        const revertAnswers = [...revertSub.answers];
        const revertIndex = revertAnswers.findIndex((a) => a.id === answerId);
        if (revertIndex !== -1) {
          const revertAnswer = { ...revertAnswers[revertIndex] };
          revertAnswer.degree_of_confidence = oldConfidence;
          revertAnswer.status = oldAnswer.status; // revert status too
          revertAnswers[revertIndex] = revertAnswer;
          revertSub.answers = revertAnswers;
          newSubs[subIndex] = revertSub;
        }
        return newSubs;
      });
    }
  };

  //
  // Editing the correct answer logic
  //
  function startEditingCorrectAnswer(questionNumber) {
    const question = questionMap[questionNumber];
    if (!question) return;
    
    // Don't allow editing for MCQ and multiple-select questions
    if (question.question_type === 'mcq' || question.question_type === 'multiple_select') {
      return;
    }
    
    setEditingCorrectAnswer((prev) => ({
      ...prev,
      [questionNumber]: question.correct_answer ?? '',
    }));
  }
  function cancelEditingCorrectAnswer(questionNumber) {
    setEditingCorrectAnswer((prev) => ({
      ...prev,
      [questionNumber]: null,
    }));
  }
  function handleCorrectAnswerChange(questionNumber, newVal) {
    setEditingCorrectAnswer((prev) => ({
      ...prev,
      [questionNumber]: newVal,
    }));
  }

  //
  // Attempt to save updated correct answer => show re-grade modal
  //
  function saveCorrectAnswer(questionNumber) {
    const newValue = editingCorrectAnswer[questionNumber];
    
    // Proceed with regrade modal
    setCurrentEditQuestionNumber(questionNumber);
    setCurrentEditNewValue(newValue);
    setShowRegradeModal(true);
  }

  //
  // Actually run the "update question" call
  //
  async function doUpdateCorrectAnswer(questionNumber, newValue) {
    if (!assignmentId) return;
    const question = questionMap[questionNumber];
    if (!question) return;
    try {
      // Update only applies to text questions
      await api.updateAssignmentQuestions(assignmentId, [
        { id: question.id, correct_answer: newValue }
      ]);
      
      // Update local state
      setQuestionMap((prev) => ({
        ...prev,
        [questionNumber]: {
          ...prev[questionNumber],
          correct_answer: newValue,
        },
      }));
    } catch (err) {
      console.error('Error updating correct answer:', err);
      alert(`Erreur lors de la mise à jour de la réponse: ${err.message}`);
    }
  }

  //
  // If user chooses "Oui, re-corriger"
  //
  async function handleConfirmRegrade() {
    setRegrading(true);
    try {
      // 1) Update the question
      await doUpdateCorrectAnswer(currentEditQuestionNumber, currentEditNewValue);

      // 2) Then regrade (background job)
      const question = questionMap[currentEditQuestionNumber];
      if (question && question.id) {
        const resp = await api.regradeQuestion(assignmentId, question.id);
        toast.success(resp.message || 'La re-correction a été déclenchée !');
      }
    } catch (err) {
      console.error('Error regrading question:', err);
      toast.error(err.message || 'Échec lors de la re-correction.');
    } finally {
      setShowRegradeModal(false);
      setRegrading(false);

      setEditingCorrectAnswer((prev) => ({
        ...prev,
        [currentEditQuestionNumber]: null,
      }));
      setCurrentEditQuestionNumber(null);
      setCurrentEditNewValue('');
    }
  }

  //
  // If user chooses "Non, enregistrer uniquement"
  //
  async function handleSaveNoRegrade() {
    try {
      // Only update the correct answer
      await doUpdateCorrectAnswer(currentEditQuestionNumber, currentEditNewValue);
      toast.success('Réponse correcte mise à jour (pas de re-correction).');
    } catch (err) {
      toast.error(err.message || 'Échec de la mise à jour.');
    } finally {
      setShowRegradeModal(false);
      setEditingCorrectAnswer((prev) => ({
        ...prev,
        [currentEditQuestionNumber]: null,
      }));
      setCurrentEditQuestionNumber(null);
      setCurrentEditNewValue('');
    }
  }

  const handleResize = (column, newWidth) => {
    setColumnWidths((prev) => ({
      ...prev,
      [column]: newWidth,
    }));
  };

  //
  // Helper to get color based on confidence
  //
  function getConfidenceCircleColor(confidence) {
    if (confidence >= CONFIDENCE_THRESHOLD_GREEN) {
      return 'bg-green-500 dark:bg-green-600';
    } else if (confidence >= CONFIDENCE_THRESHOLD_ORANGE) {
      return 'bg-amber-500 dark:bg-amber-600';
    } else {
      return 'bg-red-500 dark:bg-red-600';
    }
  }

  // Render confidence indicator with progress bar instead of just a circle
  function renderConfidenceIndicator(confidence) {
    const confidencePercent = (confidence * 100).toFixed(0);
    const width = `${confidencePercent}%`;
    let bgColor = '';
    let textColor = '';
    
    if (confidence >= CONFIDENCE_THRESHOLD_GREEN) {
      bgColor = 'bg-green-500 dark:bg-green-600';
      textColor = 'text-green-700 dark:text-green-400';
    } else if (confidence >= CONFIDENCE_THRESHOLD_ORANGE) {
      bgColor = 'bg-amber-500 dark:bg-amber-600';
      textColor = 'text-amber-700 dark:text-amber-400';
    } else {
      bgColor = 'bg-red-500 dark:bg-red-600';
      textColor = 'text-red-700 dark:text-red-400';
    }
    
    return (
      <div className="w-full flex flex-col items-center gap-1" title={`${confidencePercent}% de confiance`}>
        <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden">
          <div className={`h-full ${bgColor}`} style={{ width }}></div>
        </div>
        <span className={`text-xs font-medium ${textColor}`}>{confidencePercent}%</span>
      </div>
    );
  }

  // Modify handleUnlockAnswer to handle unlock directly
  const handleUnlockAnswer = async (subIndex, answerId) => {
    const sub = submissions[subIndex];
    if (!sub) return;

    try {
      // Call the API to unlock the answer
      await api.unlockAnswer(sub.assignment_id, sub.student_email, answerId);

      // Update local state
      setSubmissions((prevSubs) => {
        const newSubs = [...prevSubs];
        const currentSub = { ...newSubs[subIndex] };
        const newAnswers = [...currentSub.answers];
        const answerIndex = newAnswers.findIndex((a) => a.id === answerId);
        
        if (answerIndex !== -1) {
          const updatedAnswer = { ...newAnswers[answerIndex] };
          updatedAnswer.status = 'graded';
          newAnswers[answerIndex] = updatedAnswer;
          currentSub.answers = newAnswers;
          newSubs[subIndex] = currentSub;
        }
        
        return newSubs;
      });

      toast.success('Réponse déverrouillée avec succès');
    } catch (err) {
      console.error(err);
      toast.error(`Erreur lors du déverrouillage: ${err.message}`);
    }
  };

  // Function to organize all answers by question number
  const organizeByQuestion = () => {
    if (!submissions || submissions.length === 0) return [];
    
    // First, collect all unique question numbers
    const questionNumbers = new Set();
    submissions.forEach(sub => {
      if (sub.answers && sub.answers.length > 0) {
        sub.answers.forEach(answer => {
          if (answer.question_number) {
            questionNumbers.add(answer.question_number);
          }
        });
      }
    });
    
    // Sort question numbers
    const sortedQuestionNumbers = Array.from(questionNumbers).sort((a, b) => a - b);
    
    // For each question number, collect all answers across students
    return sortedQuestionNumbers.map(qNum => {
      const answersForQuestion = [];
      
      submissions.forEach(sub => {
        const matchingAnswer = sub.answers?.find(a => a.question_number === qNum);
        if (matchingAnswer) {
          answersForQuestion.push({
            ...matchingAnswer,
            student_name: sub.student_name || '(Inconnu)',
            student_email: sub.student_email,
            submission_id: sub.id
          });
        }
      });
      
      return {
        question_number: qNum,
        question_text: answersForQuestion[0]?.question || '',
        answers: answersForQuestion,
        // Find the correct answer from questionMap
        correct_answer: questionMap[qNum]?.correct_answer || ''
      };
    });
  };

  return (
    <div>
      {error && (
        <div className="mb-4 bg-destructive/20 text-destructive p-3 rounded-md">
          <strong className="font-bold">Erreur : </strong>
          <span>{error}</span>
        </div>
      )}

      {loading && (
        <div className="flex flex-col items-center mb-4 p-8">
          <Spinner />
          <em className="mt-2 text-muted-foreground">Chargement des soumissions...</em>
        </div>
      )}

      {!loading && submissions.length === 0 && (
        <div className="p-8 text-center border rounded-md bg-muted/20">
          <p className="text-sm text-muted-foreground">
            Aucune soumission trouvée pour ce devoir.
          </p>
        </div>
      )}

      {!loading && submissions.length > 0 && (
        <Tabs defaultValue="student" className="w-full" onValueChange={setViewMode} value={viewMode}>
          <div className="flex justify-between mb-4">
            <div>
              <Button
                variant="outline"
                className="flex items-center gap-1"
                onClick={handleBulkLock}
                disabled={bulkLocking}
              >
                {bulkLocking ? <Spinner className="h-4 w-4" /> : (
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 4.63601C5 3.76031 5.24219 3.1054 5.64323 2.67357C6.03934 2.24705 6.64582 1.9783 7.5014 1.9783C8.35745 1.9783 8.96306 2.24652 9.35823 2.67208C9.75838 3.10299 10 3.75708 10 4.63325V5.99999H5V4.63601ZM4 5.99999V4.63601C4 3.58148 4.29339 2.65754 4.91049 1.99307C5.53252 1.32329 6.42675 0.978302 7.5014 0.978302C8.57583 0.978302 9.46952 1.32233 10.091 1.99162C10.7076 2.65557 11 3.57896 11 4.63325V5.99999H12C12.5523 5.99999 13 6.44771 13 6.99999V13C13 13.5523 12.5523 14 12 14H3C2.44772 14 2 13.5523 2 13V6.99999C2 6.44771 2.44772 5.99999 3 5.99999H4Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
                  </svg>
                )}
                Verrouiller toutes les réponses
              </Button>
            </div>
            <TabsList>
              <TabsTrigger value="student">Par élève</TabsTrigger>
              <TabsTrigger value="question">Par question</TabsTrigger>
            </TabsList>
          </div>
          
          {/* View by student */}
          <TabsContent value="student" className="mt-0">
            <Table className="bg-card border border-border rounded-md overflow-hidden text-sm">
              <TableHeader>
                <TableRow className="bg-muted/20">
                  <TableHead className="py-3 font-semibold">
                    <div className="flex flex-col gap-1">
                      <span>Nom de l'élève</span>
                      <span className="font-normal text-xs text-muted-foreground">
                        {submissions.length} {submissions.length === 1 ? 'soumission' : 'soumissions'}
                      </span>
                    </div>
                  </TableHead>
                  <TableHead className="font-semibold">Email</TableHead>
                  <TableHead className="font-semibold">Créée</TableHead>
                  <TableHead className="font-semibold">Note Totale</TableHead>
                  <TableHead className="font-semibold">Incertitudes</TableHead>
                  <TableHead className="font-semibold">Statut</TableHead>
                  <TableHead className="text-center font-semibold">Détails</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {submissions.map((sub, subIndex) => {
                  const key = `${sub.assignment_id}_${sub.student_email}`;
                  const isExpanded = expandedKeys.includes(key);
                  const { totalEarned, totalPossible } = computeTotalGrade(sub);
                  const totalGrade =
                    totalPossible > 0
                      ? `${totalEarned.toFixed(2)} / ${totalPossible.toFixed(2)}`
                      : '--';

                  // Determine overall submission status
                  const submissionStatus = getSubmissionStatus(sub.answers || []);

                  return (
                    <React.Fragment key={key}>
                      <TableRow className="hover:bg-muted/10">
                        <TableCell className="font-medium">{sub.student_name || '(Inconnu)'}</TableCell>
                        <TableCell>{sub.student_email}</TableCell>
                        <TableCell>
                          {forceUtcThenConvertToMontreal(sub.created_at)}
                        </TableCell>
                        <TableCell className="font-medium">{totalGrade}</TableCell>
                        <TableCell>
                          <div className="flex gap-1">
                            {sub.answers?.filter(a => a.degree_of_confidence < CONFIDENCE_THRESHOLD_ORANGE)
                              .map((_, idx) => (
                                <div key={idx} className="w-4 h-4 rounded-full bg-red-500 dark:bg-red-600" title="Faible confiance" />
                              ))}
                          </div>
                        </TableCell>
                        <TableCell>
                          {renderSubmissionStatus(submissionStatus)}
                        </TableCell>
                        <TableCell className="text-center">
                          <Button
                            variant={isExpanded ? "default" : "outline"}
                            size="sm"
                            onClick={() => toggleExpand(sub.assignment_id, sub.student_email)}
                          >
                            {isExpanded ? 'Masquer' : 'Afficher'}
                          </Button>
                        </TableCell>
                      </TableRow>

                      {isExpanded && (
                        <TableRow className="bg-muted/10">
                          <TableCell colSpan={7} className="p-4">
                            {sub.answers && sub.answers.length > 0 ? (
                              <div className="overflow-x-auto rounded-md border border-border">
                                {[...sub.answers]
                                  .sort((a, b) => a.question_number - b.question_number)
                                  .reduce((groups, answer, index) => {
                                    if (index % 1 === 0) {
                                      groups.push([]);
                                    }
                                    groups[groups.length - 1].push(answer);
                                    return groups;
                                  }, [])
                                  .map((group, groupIndex) => (
                                    <Table
                                      key={groupIndex}
                                      className="mt-2 w-full text-sm"
                                    >
                                      <TableHeader>
                                        <TableRow className="bg-accent/20">
                                          <ResizableHeader
                                            width={columnWidths.questionNumber}
                                            onResize={(w) => handleResize('questionNumber', w)}
                                          >
                                            Q #
                                          </ResizableHeader>
                                          <ResizableHeader
                                            width={columnWidths.question}
                                            onResize={(w) => handleResize('question', w)}
                                          >
                                            Question
                                          </ResizableHeader>
                                          <ResizableHeader
                                            width={columnWidths.correct}
                                            onResize={(w) => handleResize('correct', w)}
                                          >
                                            Corrigé
                                          </ResizableHeader>
                                          <ResizableHeader
                                            width={columnWidths.answer}
                                            onResize={(w) => handleResize('answer', w)}
                                          >
                                            Réponse élève
                                          </ResizableHeader>
                                          <ResizableHeader
                                            width={columnWidths.grade}
                                            onResize={(w) => handleResize('grade', w)}
                                          >
                                            Note
                                          </ResizableHeader>
                                          <ResizableHeader
                                            width={columnWidths.justification}
                                            onResize={(w) => handleResize('justification', w)}
                                          >
                                            Justification
                                          </ResizableHeader>
                                          <ResizableHeader
                                            width={columnWidths.confidence}
                                            onResize={(w) => handleResize('confidence', w)}
                                          >
                                            Confiance
                                          </ResizableHeader>
                                          <ResizableHeader
                                            width={columnWidths.status}
                                            onResize={(w) => handleResize('status', w)}
                                          >
                                            Status
                                          </ResizableHeader>
                                        </TableRow>
                                      </TableHeader>
                                      <TableBody>
                                        {group.map((answerItem) => {
                                          const confidencePercent = (answerItem.degree_of_confidence * 100).toFixed(0) + '%';
                                          const questionNumber = answerItem.question_number;
                                          const isEditing =
                                            editingCorrectAnswer[questionNumber] !== null &&
                                            editingCorrectAnswer[questionNumber] !== undefined;

                                          let officialCorrectAnswer = '';
                                          if (questionNumber && questionMap[questionNumber]) {
                                            officialCorrectAnswer =
                                              questionMap[questionNumber].correct_answer || '';
                                          } else {
                                            officialCorrectAnswer = answerItem.correct_answer || '';
                                          }

                                          // local grade
                                          const localEditVal = editedGrades[answerItem.id];
                                          const showSaveButton =
                                            localEditVal !== undefined &&
                                            localEditVal !== '' + answerItem.grade;

                                          return (
                                            <TableRow key={answerItem.id} className="hover:bg-muted/5">
                                              <TableCell
                                                style={{
                                                  width: `${columnWidths.questionNumber}px`,
                                                  borderRight: '1px solid rgb(226, 232, 240)',
                                                }}
                                                className="font-medium"
                                              >
                                                Q#{questionNumber}
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: `${columnWidths.question}px`,
                                                  borderRight: '1px solid rgb(226, 232, 240)',
                                                }}
                                              >
                                                {answerItem.question}
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: `${columnWidths.correct}px`,
                                                  borderRight: '1px solid rgb(226, 232, 240)',
                                                }}
                                              >
                                                {!isEditing ? (
                                                  <div>
                                                    {questionMap[questionNumber]?.question_type === 'mcq' && questionMap[questionNumber]?.question_metadata?.choices ? (
                                                      <div className="space-y-1">
                                                        {questionMap[questionNumber].question_metadata.choices.map((choice, idx) => {
                                                          const isCorrect = idx === questionMap[questionNumber].question_metadata.correct_index;
                                                          return (
                                                            <div key={idx} className={`flex items-center gap-1.5 px-2 py-1 rounded ${isCorrect ? 'bg-green-50 dark:bg-green-900/20' : ''}`}>
                                                              <div className={`w-4 h-4 rounded-full flex items-center justify-center border ${isCorrect ? 'bg-green-500 border-green-600 text-white' : 'border-gray-300'}`}>
                                                                {isCorrect && <span className="text-xs">✓</span>}
                                                              </div>
                                                              <span className={isCorrect ? 'font-medium' : 'text-gray-600 dark:text-gray-400'}>
                                                                {choice}
                                                              </span>
                                                            </div>
                                                          );
                                                        })}
                                                      </div>
                                                    ) : questionMap[questionNumber]?.question_type === 'multiple_select' && questionMap[questionNumber]?.question_metadata?.choices ? (
                                                      <div className="space-y-1">
                                                        {questionMap[questionNumber].question_metadata.choices.map((choice, idx) => {
                                                          const isCorrect = questionMap[questionNumber].question_metadata.correct_indices?.includes(idx);
                                                          return (
                                                            <div key={idx} className={`flex items-center gap-1.5 px-2 py-1 rounded ${isCorrect ? 'bg-green-50 dark:bg-green-900/20' : ''}`}>
                                                              <div className={`w-4 h-4 rounded flex items-center justify-center border ${isCorrect ? 'bg-green-500 border-green-600 text-white' : 'border-gray-300'}`}>
                                                                {isCorrect && <span className="text-xs">✓</span>}
                                                              </div>
                                                              <span className={isCorrect ? 'font-medium' : 'text-gray-600 dark:text-gray-400'}>
                                                                {choice}
                                                              </span>
                                                            </div>
                                                          );
                                                        })}
                                                      </div>
                                                    ) : (
                                                      <div
                                                        className="cursor-pointer hover:underline text-green-600 dark:text-green-400"
                                                        onClick={() => startEditingCorrectAnswer(questionNumber)}
                                                        title="Cliquez pour modifier"
                                                      >
                                                        {officialCorrectAnswer || '(aucune)'}
                                                      </div>
                                                    )}
                                                  </div>
                                                ) : (
                                                  <div className="flex flex-col space-y-2">
                                                    <Textarea
                                                      className="min-h-[150px] w-full resize-y text-base p-3"
                                                      value={editingCorrectAnswer[questionNumber] ?? ''}
                                                      onChange={(e) =>
                                                        handleCorrectAnswerChange(questionNumber, e.target.value)
                                                      }
                                                      placeholder="Entrez la réponse correcte ici..."
                                                    />
                                                    <div className="flex justify-end space-x-2">
                                                      <Button
                                                        variant="outline"
                                                        size="sm"
                                                        onClick={() =>
                                                          cancelEditingCorrectAnswer(questionNumber)
                                                        }
                                                      >
                                                        Annuler
                                                      </Button>
                                                      <Button
                                                        variant="outline"
                                                        size="sm"
                                                        onClick={() => saveCorrectAnswer(questionNumber)}
                                                      >
                                                        Enregistrer
                                                      </Button>
                                                    </div>
                                                  </div>
                                                )}
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: `${columnWidths.answer}px`,
                                                  borderRight: '1px solid rgb(226, 232, 240)',
                                                }}
                                                className="text-blue-600 dark:text-blue-400"
                                              >
                                                {/* Check if this is for an MCQ question */}
                                                {questionNumber && questionMap[questionNumber]?.question_type === 'mcq' && questionMap[questionNumber]?.question_metadata?.choices ? (
                                                  <div className="space-y-1">
                                                    {questionMap[questionNumber].question_metadata.choices.map((choice, idx) => {
                                                      // Try different matching strategies
                                                      const isSelected = 
                                                        answerItem.answer === choice || 
                                                        answerItem.answer === idx.toString() ||
                                                        answerItem.answer?.toLowerCase().includes(choice.toLowerCase());
                                                      
                                                      return (
                                                        <div key={idx} className={`flex items-center gap-1.5 px-2 py-1 rounded ${isSelected ? 'bg-blue-50 dark:bg-blue-900/20' : ''}`}>
                                                          <div className={`w-4 h-4 rounded-full flex items-center justify-center border ${isSelected ? 'bg-blue-500 border-blue-600 text-white' : 'border-gray-300'}`}>
                                                            {isSelected && <span className="text-xs">✓</span>}
                                                          </div>
                                                          <span className={isSelected ? 'font-medium' : 'text-gray-600 dark:text-gray-400'}>
                                                            {choice}
                                                          </span>
                                                        </div>
                                                      );
                                                    })}
                                                  </div>
                                                ) : questionNumber && questionMap[questionNumber]?.question_type === 'multiple_select' && questionMap[questionNumber]?.question_metadata?.choices ? (
                                                  <div className="space-y-1">
                                                    {questionMap[questionNumber].question_metadata.choices.map((choice, idx) => {
                                                      // For multiple select, try to match if this choice appears in the answer
                                                      const answerLower = answerItem.answer?.toLowerCase() || '';
                                                      const isSelected = 
                                                        answerLower.includes(choice.toLowerCase()) || 
                                                        (answerLower.includes(',') && answerLower.split(',').some(part => part.trim().toLowerCase() === choice.toLowerCase()));
                                                      
                                                      return (
                                                        <div key={idx} className={`flex items-center gap-1.5 px-2 py-1 rounded ${isSelected ? 'bg-purple-50 dark:bg-purple-900/20' : ''}`}>
                                                          <div className={`w-4 h-4 rounded flex items-center justify-center border ${isSelected ? 'bg-purple-500 border-purple-600 text-white' : 'border-gray-300'}`}>
                                                            {isSelected && <span className="text-xs">✓</span>}
                                                          </div>
                                                          <span className={isSelected ? 'font-medium' : 'text-gray-600 dark:text-gray-400'}>
                                                            {choice}
                                                          </span>
                                                        </div>
                                                      );
                                                    })}
                                                  </div>
                                                ) : (
                                                  answerItem.answer || ''
                                                )}
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: `${columnWidths.grade}px`,
                                                  borderRight: '1px solid rgb(226, 232, 240)',
                                                }}
                                              >
                                                <div className="flex flex-col">
                                                  <div className="flex items-center gap-1">
                                                    <Input
                                                      type="number"
                                                      step="0.5"
                                                      className="w-16"
                                                      value={
                                                        localEditVal !== undefined
                                                          ? localEditVal
                                                          : answerItem.grade
                                                      }
                                                      disabled={
                                                        answerItem.status === 'grading' ||
                                                        answerItem.status === 'locked'
                                                      }
                                                      onChange={(e) =>
                                                        handleGradeChangeLocal(answerItem.id, e.target.value)
                                                      }
                                                    />
                                                    <span className="text-muted-foreground">/{answerItem.max_points}</span>
                                                  </div>
                                                  {/* Always reserve fixed width space for buttons to prevent layout shift */}
                                                  <div className="h-7 mt-2 flex justify-center" style={{ width: "80px" }}>
                                                    {showSaveButton && (
                                                      <div className="flex gap-1">
                                                        <Tooltip content="Annuler" delayDuration={100}>
                                                          <Button
                                                            variant="outline"
                                                            size="xs"
                                                            className="px-2 py-0 h-7 text-xs"
                                                            onClick={() => {
                                                              // Reset the edited grade to the original value
                                                              setEditedGrades(prev => {
                                                                const next = { ...prev };
                                                                delete next[answerItem.id];
                                                                return next;
                                                              });
                                                            }}
                                                          >
                                                            ✕
                                                          </Button>
                                                        </Tooltip>
                                                        <Tooltip content="Sauvegarder" delayDuration={100}>
                                                          <Button
                                                            variant="default"
                                                            size="xs"
                                                            className="px-2 py-0 h-7 text-xs"
                                                            onClick={() => handleSaveGrade(subIndex, answerItem.id)}
                                                          >
                                                            ✓
                                                          </Button>
                                                        </Tooltip>
                                                      </div>
                                                    )}
                                                  </div>
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: `${columnWidths.justification}px`,
                                                  borderRight: '1px solid rgb(226, 232, 240)',
                                                }}
                                              >
                                                <div className="max-h-[150px] overflow-y-auto">
                                                  {answerItem.justification}
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: `${columnWidths.confidence}px`,
                                                  borderRight: '1px solid rgb(226, 232, 240)',
                                                }}
                                                className="text-center"
                                              >
                                                <div className="flex flex-col items-center gap-1">
                                                  {renderConfidenceIndicator(answerItem.degree_of_confidence)}
                                                  {answerItem.degree_of_confidence < 0.95 && answerItem.status !== 'locked' && (
                                                    <Button
                                                      variant="outline"
                                                      size="sm"
                                                      className="mt-1"
                                                      onClick={() => handleConfirmConfidence(subIndex, answerItem.id)}
                                                    >
                                                      Valider
                                                    </Button>
                                                  )}
                                                </div>
                                              </TableCell>
                                              <TableCell
                                                style={{
                                                  width: `${columnWidths.status}px`,
                                                }}
                                              >
                                                {renderAnswerStatus(
                                                  answerItem,
                                                  subIndex,
                                                  handleConfirmConfidence,
                                                  handleUnlockAnswer
                                                )}
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                  ))}
                              </div>
                            ) : (
                              <div className="text-center p-4 bg-muted/10 rounded-md">
                                <span className="text-sm text-muted-foreground">
                                  Aucune note disponible.
                                </span>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TabsContent>
          
          {/* View by question */}
          <TabsContent value="question" className="mt-0">
            <Table className="bg-card border border-border rounded-md overflow-hidden text-sm">
              <TableHeader>
                <TableRow className="bg-muted/20">
                  <TableHead className="py-3 font-semibold">Numéro</TableHead>
                  <TableHead className="font-semibold">Question</TableHead>
                  <TableHead className="font-semibold">Réponse Correcte</TableHead>
                  <TableHead className="font-semibold">Note Moyenne</TableHead>
                  <TableHead className="font-semibold">Confiance Moyenne</TableHead>
                  <TableHead className="text-center font-semibold">Détails</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {organizeByQuestion().map((questionGroup) => {
                  const isExpanded = expandedQuestions.includes(questionGroup.question_number);
                  
                  // Calculate average confidence
                  const confidenceSum = questionGroup.answers.reduce(
                    (sum, ans) => sum + ans.degree_of_confidence, 
                    0
                  );
                  const avgConfidence = questionGroup.answers.length > 0 
                    ? confidenceSum / questionGroup.answers.length 
                    : 0;
                    
                  // Validate if all answers have the same max_points
                  const maxPointsSet = new Set(questionGroup.answers.map(ans => ans.max_points));
                  const hasSameMaxPoints = maxPointsSet.size === 1;
                  
                  // Calculate average grade
                  const gradeSum = questionGroup.answers.reduce(
                    (sum, ans) => sum + ans.grade, 
                    0
                  );
                  const avgGrade = questionGroup.answers.length > 0 
                    ? gradeSum / questionGroup.answers.length 
                    : 0;
                  
                  // Get max points (if consistent)
                  const maxPoints = hasSameMaxPoints && questionGroup.answers.length > 0
                    ? questionGroup.answers[0].max_points
                    : null;
                  
                  return (
                    <React.Fragment key={`question-${questionGroup.question_number}`}>
                      <TableRow className="hover:bg-muted/10">
                        <TableCell className="font-medium">Q#{questionGroup.question_number}</TableCell>
                        <TableCell>{questionGroup.question_text}</TableCell>
                        <TableCell>
                          {editingCorrectAnswer[questionGroup.question_number] !== null &&
                           editingCorrectAnswer[questionGroup.question_number] !== undefined ? (
                            <div className="flex flex-col space-y-2">
                              <Textarea
                                className="min-h-[150px] w-full resize-y text-base p-3"
                                value={editingCorrectAnswer[questionGroup.question_number] ?? ''}
                                onChange={(e) =>
                                  handleCorrectAnswerChange(questionGroup.question_number, e.target.value)
                                }
                                placeholder="Entrez la réponse correcte ici..."
                              />
                              <div className="flex justify-end space-x-2">
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() =>
                                    cancelEditingCorrectAnswer(questionGroup.question_number)
                                  }
                                >
                                  Annuler
                                </Button>
                                <Button
                                  variant="outline"
                                  size="sm"
                                  onClick={() => saveCorrectAnswer(questionGroup.question_number)}
                                >
                                  Enregistrer
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div>
                              {questionMap[questionGroup.question_number]?.question_type === 'mcq' && questionMap[questionGroup.question_number]?.question_metadata?.choices ? (
                                <div className="space-y-1">
                                  {questionMap[questionGroup.question_number].question_metadata.choices.map((choice, idx) => {
                                    const isCorrect = idx === questionMap[questionGroup.question_number].question_metadata.correct_index;
                                    return (
                                      <div key={idx} className={`flex items-center gap-1.5 px-2 py-1 rounded ${isCorrect ? 'bg-green-50 dark:bg-green-900/20' : ''}`}>
                                        <div className={`w-4 h-4 rounded-full flex items-center justify-center border ${isCorrect ? 'bg-green-500 border-green-600 text-white' : 'border-gray-300'}`}>
                                          {isCorrect && <span className="text-xs">✓</span>}
                                        </div>
                                        <span className={isCorrect ? 'font-medium' : 'text-gray-600 dark:text-gray-400'}>
                                          {choice}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : questionMap[questionGroup.question_number]?.question_type === 'multiple_select' && questionMap[questionGroup.question_number]?.question_metadata?.choices ? (
                                <div className="space-y-1">
                                  {questionMap[questionGroup.question_number].question_metadata.choices.map((choice, idx) => {
                                    const isCorrect = questionMap[questionGroup.question_number].question_metadata.correct_indices?.includes(idx);
                                    return (
                                      <div key={idx} className={`flex items-center gap-1.5 px-2 py-1 rounded ${isCorrect ? 'bg-green-50 dark:bg-green-900/20' : ''}`}>
                                        <div className={`w-4 h-4 rounded flex items-center justify-center border ${isCorrect ? 'bg-green-500 border-green-600 text-white' : 'border-gray-300'}`}>
                                          {isCorrect && <span className="text-xs">✓</span>}
                                        </div>
                                        <span className={isCorrect ? 'font-medium' : 'text-gray-600 dark:text-gray-400'}>
                                          {choice}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <div 
                                  className="cursor-pointer hover:underline text-green-600 dark:text-green-400"
                                  onClick={() => startEditingCorrectAnswer(questionGroup.question_number)}
                                  title="Cliquez pour modifier"
                                >
                                  {questionGroup.correct_answer || '(aucune)'}
                                </div>
                              )}
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {hasSameMaxPoints ? (
                            <div className="flex items-center gap-1.5">
                              <span className="font-medium">{Math.round((avgGrade / maxPoints) * 100)}%</span>
                              <span className="text-muted-foreground text-xs ml-1">({avgGrade.toFixed(1)}/{maxPoints})</span>
                            </div>
                          ) : (
                            <div className="text-amber-500 text-xs flex items-center gap-1">
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.4449 0.608765C8.0183 -0.107015 6.9817 -0.107015 6.55509 0.608766L0.161178 11.3368C-0.275824 12.07 0.252503 13 1.10608 13H13.8939C14.7475 13 15.2758 12.07 14.8388 11.3368L8.4449 0.608765ZM7.4141 1.12073C7.45273 1.05566 7.54727 1.05566 7.5859 1.12073L13.9798 11.8488C14.0191 11.9147 13.9697 12 13.8939 12H1.10608C1.03032 12 0.980909 11.9147 1.02018 11.8488L7.4141 1.12073ZM6.8269 4.48611C6.81221 4.10423 7.11783 3.78541 7.5 3.78541C7.88217 3.78541 8.18778 4.10423 8.1731 4.48611L8.01921 8.48611C8.00848 8.76053 7.78147 8.98541 7.5 8.98541C7.21853 8.98541 6.99152 8.76053 6.98079 8.48611L6.8269 4.48611ZM8.24989 10.9988C8.24989 11.4124 7.9135 11.7488 7.49989 11.7488C7.08627 11.7488 6.74989 11.4124 6.74989 10.9988C6.74989 10.5852 7.08627 10.2488 7.49989 10.2488C7.9135 10.2488 8.24989 10.5852 8.24989 10.9988Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"></path>
                              </svg>
                              <span>Valeurs max différentes</span>
                            </div>
                          )}
                        </TableCell>
                        <TableCell>
                          {renderConfidenceIndicator(avgConfidence)}
                        </TableCell>
                        <TableCell className="text-center">
                          <Button
                            variant={isExpanded ? "default" : "outline"}
                            size="sm"
                            onClick={() => toggleExpandQuestion(questionGroup.question_number)}
                          >
                            {isExpanded ? 'Masquer' : 'Afficher'}
                          </Button>
                        </TableCell>
                      </TableRow>

                      {isExpanded && (
                        <TableRow className="bg-muted/10">
                          <TableCell colSpan={6} className="p-4">
                            {questionGroup.answers.length > 0 ? (
                              <div className="overflow-x-auto rounded-md border border-border"
                                   style={{ width: "100%" }}>
                                <Table className="w-full text-sm">
                                  <TableHeader>
                                    <TableRow className="bg-accent/20">
                                      <ResizableHeader
                                        width={columnWidths.student}
                                        onResize={(w) => handleResize('student', w)}
                                      >
                                        <div className="flex flex-col gap-1">
                                          <span>Élève</span>
                                          <span className="font-normal text-xs text-muted-foreground">
                                            {questionGroup.answers.length} {questionGroup.answers.length === 1 ? 'réponse' : 'réponses'}
                                          </span>
                                        </div>
                                      </ResizableHeader>
                                      <ResizableHeader
                                        width={columnWidths.answer}
                                        onResize={(w) => handleResize('answer', w)}
                                      >
                                        Réponse élève
                                      </ResizableHeader>
                                      <ResizableHeader
                                        width={columnWidths.grade}
                                        onResize={(w) => handleResize('grade', w)}
                                      >
                                        Note
                                      </ResizableHeader>
                                      <ResizableHeader
                                        width={columnWidths.justification}
                                        onResize={(w) => handleResize('justification', w)}
                                      >
                                        Justification
                                      </ResizableHeader>
                                      <ResizableHeader
                                        width={columnWidths.confidence}
                                        onResize={(w) => handleResize('confidence', w)}
                                      >
                                        Confiance
                                      </ResizableHeader>
                                      <ResizableHeader
                                        width={columnWidths.status}
                                        onResize={(w) => handleResize('status', w)}
                                      >
                                        Status
                                      </ResizableHeader>
                                    </TableRow>
                                  </TableHeader>
                                  <TableBody>
                                    {questionGroup.answers.map((answerItem) => {
                                      // This is important: we need the correct submission index
                                      // for each student's answer when organizing by question
                                      const subIndex = submissions.findIndex(
                                        sub => sub.student_email === answerItem.student_email
                                      );
                                      
                                      // local grade
                                      const localEditVal = editedGrades[answerItem.id];
                                      const showSaveButton =
                                        localEditVal !== undefined &&
                                        localEditVal !== '' + answerItem.grade;

                                      return (
                                        <TableRow key={answerItem.id} className="hover:bg-muted/5">
                                          <TableCell
                                            style={{
                                              width: `${columnWidths.student}px`,
                                              borderRight: '1px solid rgb(226, 232, 240)',
                                            }}
                                            className="font-medium"
                                          >
                                            {answerItem.student_name}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: `${columnWidths.answer}px`,
                                              borderRight: '1px solid rgb(226, 232, 240)',
                                            }}
                                            className="text-blue-600 dark:text-blue-400"
                                          >
                                            {/* Check if this is for an MCQ question */}
                                            {questionGroup.question_number && questionMap[questionGroup.question_number]?.question_type === 'mcq' && questionMap[questionGroup.question_number]?.question_metadata?.choices ? (
                                              <div className="space-y-1">
                                                {questionMap[questionGroup.question_number].question_metadata.choices.map((choice, idx) => {
                                                  // Try different matching strategies
                                                  const isSelected = 
                                                    answerItem.answer === choice || 
                                                    answerItem.answer === idx.toString() ||
                                                    answerItem.answer?.toLowerCase().includes(choice.toLowerCase());
                                                  
                                                  return (
                                                    <div key={idx} className={`flex items-center gap-1.5 px-2 py-1 rounded ${isSelected ? 'bg-blue-50 dark:bg-blue-900/20' : ''}`}>
                                                      <div className={`w-4 h-4 rounded-full flex items-center justify-center border ${isSelected ? 'bg-blue-500 border-blue-600 text-white' : 'border-gray-300'}`}>
                                                        {isSelected && <span className="text-xs">✓</span>}
                                                      </div>
                                                      <span className={isSelected ? 'font-medium' : 'text-gray-600 dark:text-gray-400'}>
                                                        {choice}
                                                      </span>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            ) : questionGroup.question_number && questionMap[questionGroup.question_number]?.question_type === 'multiple_select' && questionMap[questionGroup.question_number]?.question_metadata?.choices ? (
                                              <div className="space-y-1">
                                                {questionMap[questionGroup.question_number].question_metadata.choices.map((choice, idx) => {
                                                  // For multiple select, try to match if this choice appears in the answer
                                                  const answerLower = answerItem.answer?.toLowerCase() || '';
                                                  const isSelected = 
                                                    answerLower.includes(choice.toLowerCase()) || 
                                                    (answerLower.includes(',') && answerLower.split(',').some(part => part.trim().toLowerCase() === choice.toLowerCase()));
                                                  
                                                  return (
                                                    <div key={idx} className={`flex items-center gap-1.5 px-2 py-1 rounded ${isSelected ? 'bg-purple-50 dark:bg-purple-900/20' : ''}`}>
                                                      <div className={`w-4 h-4 rounded flex items-center justify-center border ${isSelected ? 'bg-purple-500 border-purple-600 text-white' : 'border-gray-300'}`}>
                                                        {isSelected && <span className="text-xs">✓</span>}
                                                      </div>
                                                      <span className={isSelected ? 'font-medium' : 'text-gray-600 dark:text-gray-400'}>
                                                        {choice}
                                                      </span>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            ) : (
                                              answerItem.answer || ''
                                            )}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: `${columnWidths.grade}px`,
                                              borderRight: '1px solid rgb(226, 232, 240)',
                                            }}
                                          >
                                            <div className="flex flex-col">
                                              <div className="flex items-center gap-1">
                                                <Input
                                                  type="number"
                                                  step="0.5"
                                                  className="w-16"
                                                  value={
                                                    localEditVal !== undefined
                                                      ? localEditVal
                                                      : answerItem.grade
                                                  }
                                                  disabled={
                                                    answerItem.status === 'grading' ||
                                                    answerItem.status === 'locked'
                                                  }
                                                  onChange={(e) =>
                                                    handleGradeChangeLocal(answerItem.id, e.target.value)
                                                  }
                                                />
                                                <span className="text-muted-foreground">/{answerItem.max_points}</span>
                                              </div>
                                              {/* Always reserve fixed width space for buttons to prevent layout shift */}
                                              <div className="h-7 mt-2 flex justify-center" style={{ width: "80px" }}>
                                                {showSaveButton && (
                                                  <div className="flex gap-1">
                                                    <Tooltip content="Annuler" delayDuration={100}>
                                                      <Button
                                                        variant="outline"
                                                        size="xs"
                                                        className="px-2 py-0 h-7 text-xs"
                                                        onClick={() => {
                                                          // Reset the edited grade to the original value
                                                          setEditedGrades(prev => {
                                                            const next = { ...prev };
                                                            delete next[answerItem.id];
                                                            return next;
                                                          });
                                                        }}
                                                      >
                                                        ✕
                                                      </Button>
                                                    </Tooltip>
                                                    <Tooltip content="Sauvegarder" delayDuration={100}>
                                                      <Button
                                                        variant="default"
                                                        size="xs"
                                                        className="px-2 py-0 h-7 text-xs"
                                                        onClick={() => handleSaveGrade(subIndex, answerItem.id)}
                                                      >
                                                        ✓
                                                      </Button>
                                                    </Tooltip>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: `${columnWidths.justification}px`,
                                              borderRight: '1px solid rgb(226, 232, 240)',
                                            }}
                                          >
                                            <div className="max-h-[150px] overflow-y-auto">
                                              {answerItem.justification}
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: `${columnWidths.confidence}px`,
                                              borderRight: '1px solid rgb(226, 232, 240)',
                                            }}
                                            className="text-center"
                                          >
                                            <div className="flex flex-col items-center gap-1">
                                              {renderConfidenceIndicator(answerItem.degree_of_confidence)}
                                              {answerItem.degree_of_confidence < 0.95 && answerItem.status !== 'locked' && (
                                                <Button
                                                  variant="outline"
                                                  size="sm"
                                                  className="mt-1"
                                                  onClick={() => handleConfirmConfidence(subIndex, answerItem.id)}
                                                >
                                                  Valider
                                                </Button>
                                              )}
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: `${columnWidths.status}px`,
                                            }}
                                          >
                                            {renderAnswerStatus(
                                              answerItem,
                                              subIndex,
                                              handleConfirmConfidence,
                                              handleUnlockAnswer
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </div>
                            ) : (
                              <div className="text-center p-4 bg-muted/10 rounded-md">
                                <span className="text-sm text-muted-foreground">
                                  Aucune réponse disponible pour cette question.
                                </span>
                              </div>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TabsContent>
        </Tabs>
      )}

      {/* Replace custom modal with shadcn Dialog component */}
      <Dialog open={showRegradeModal} onOpenChange={setShowRegradeModal}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Mettre à jour le corrigé</DialogTitle>
            <DialogDescription>
              En mettant à jour le corrigé, vous pouvez choisir de re-corriger
              toutes les réponses des élèves pour cette question.
              <p className="mt-2">Cela consommera des crédits (1 crédit par re-correction).</p>
            </DialogDescription>
          </DialogHeader>
          
          {regrading ? (
            <div className="flex flex-col items-center justify-center py-4">
              <Spinner />
              <span className="mt-2 text-sm">Lancement de la re-correction...</span>
            </div>
          ) : (
            <DialogFooter className="flex justify-end space-x-2 mt-4">
              <Button variant="secondary" onClick={handleSaveNoRegrade} disabled={regrading}>
                Non, enregistrer seulement
              </Button>
              <Button variant="default" onClick={handleConfirmRegrade} disabled={regrading}>
                Oui, re-corriger
              </Button>
            </DialogFooter>
          )}
        </DialogContent>
      </Dialog>

      {/* Add GradeLogModal */}
      {gradeLogModal && (
        <GradeLogModal
          open={!!gradeLogModal}
          onClose={() => setGradeLogModal(null)}
          assignmentId={gradeLogModal.assignmentId}
          studentEmail={gradeLogModal.studentEmail}
          answerId={gradeLogModal.answerId}
          grade={gradeLogModal.grade}
          questionId={gradeLogModal.questionId}
          questionNumber={gradeLogModal.questionNumber}
          subIndex={gradeLogModal.subIndex}
          answerIndex={gradeLogModal.answerIndex}
          onUpdateComplete={handleUpdateComplete}
          question={gradeLogModal.question}
          answer={gradeLogModal.answer}
          correct_answer={gradeLogModal.correct_answer}
          old_grade={gradeLogModal.old_grade}
        />
      )}
    </div>
  );
}

export default SubmissionListWrapper;
{/* <ai_context>
api.js
We add a new API function that fetches the extended grade logs that include the student's answer
</ai_context> */}

import { API_BASE_URL, API_VERSION, API_ENDPOINTS } from './config';

const handleResponse = async (response) => {
  if (!response.ok) {
    const text = await response.text();
    let error;
    try {
      const data = JSON.parse(text);
      if (data.detail === "Token has expired") {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
      error = new Error(data.detail || 'Requête échouée');
    } catch {
      error = new Error(text || 'Requête échouée');
    }
    // Attach status and url to the error object
    error.status = response.status;
    error.url = response.url;
    throw error;
  }
  return response.json();
};

const api = {
  // Auth
  loginUser: async (email, password) => {
    const response = await fetch(`${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.LOGIN}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    });
    return handleResponse(response);
  },

  registerUser: async (email, password) => {
    const response = await fetch(`${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.REGISTER}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    });
    return handleResponse(response);
  },

  // Assignments
  getAssignment: async (assignmentId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(
      `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.ASSIGNMENTS}/${assignmentId}`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return handleResponse(response);
  },

  deleteAssignment: async (assignmentId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(
      `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.ASSIGNMENTS}/${assignmentId}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (!response.ok) {
      throw new Error(`Échec de la suppression du devoir : ${response.statusText}`);
    }
    return { message: 'Devoir supprimé' };
  },

  getAssignments: async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.ASSIGNMENTS}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  },

  // Submissions
  getSubmissionsByAssignment: async (assignmentId) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.ASSIGNMENT_SUBMISSIONS(assignmentId)}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  },
  
  getSubmissionsForAssignment: async (assignmentId) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.ASSIGNMENT_SUBMISSIONS(assignmentId)}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  },

  getSubmission: async (assignmentId, studentEmail) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.SUBMISSIONS}/${assignmentId}/${encodeURIComponent(studentEmail)}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${token}` },
    });
    return handleResponse(response);
  },

  deleteSubmission: async (assignmentId, studentEmail) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.SUBMISSIONS}/${assignmentId}/${encodeURIComponent(studentEmail)}`;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: { 'Authorization': `Bearer ${token}` },
    });
    if (!response.ok) {
      const text = await response.text();
      throw new Error(text || 'Échec de la suppression de la soumission');
    }
    return { message: 'Soumission supprimée' };
  },

  // Google Classroom
  getGoogleClassroomConnectUrl: async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.CLASSROOM_CONNECT}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  },

  listCourses: async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.CLASSROOM_COURSES}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  },

  listAssignments: async (courseId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.CLASSROOM_ASSIGNMENTS(courseId)}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  },

  listSubmissions: async (courseId, assignmentId) => {
    const token = localStorage.getItem('token');
    const response = await fetch(
      `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.CLASSROOM_SUBMISSIONS(courseId, assignmentId)}`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return handleResponse(response);
  },

  importSubmissions: async (payload) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.CLASSROOM_IMPORT_SUBMISSIONS}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    return handleResponse(response);
  },

  getCurrentUser: async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.CURRENT_USER}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  },

  // Assignment questions
  getAssignmentQuestions: async (assignmentId) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.ASSIGNMENTS}/${assignmentId}/questions`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  },

  updateAssignmentQuestions: async (assignmentId, updates) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.ASSIGNMENTS}/${assignmentId}/questions`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updates),
    });
    return handleResponse(response);
  },

  updateAnswer: async (assignmentId, studentEmail, answerId, { grade, status, explanation }) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.SUBMISSIONS}/${assignmentId}/${encodeURIComponent(studentEmail)}/answers/${answerId}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ grade, status, explanation }),
    });
    return handleResponse(response);
  },

  //
  // NEW: Re-grade a single question across all submissions
  //
  regradeQuestion: async (assignmentId, questionId) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.ASSIGNMENTS}/${assignmentId}/questions/${questionId}/regrade`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  },

  unlockAnswer: async (assignmentId, studentEmail, answerId) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}${API_ENDPOINTS.SUBMISSIONS}/${assignmentId}/${encodeURIComponent(studentEmail)}/answers/${answerId}/unlock`;
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    
    return handleResponse(response);
  },


  // Import correct answers from one assignment to another
  importCorrectAnswers: async (sourceAssignmentId, targetAssignmentId) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}/assignments/import-correct-answers`;
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        source_assignment_id: sourceAssignmentId,
        target_assignment_id: targetAssignmentId,
        import_correct_answers: true // Keep for backward compatibility
      }),
    });
    // Expects a response like { potential_updates: [...], message: "..." }
    return handleResponse(response);
  },
  // Grading Memory operations
  getGradingMemories: async (options = {}) => {
    const { skip = 0, limit = 50, questionId, assignmentId } = options;
    const token = localStorage.getItem('token');
    
    let url = `${API_BASE_URL}${API_VERSION}/grading-memory/?skip=${skip}&limit=${limit}`;
    
    if (questionId) {
      url += `&question_id=${questionId}`;
    }
    
    if (assignmentId) {
      url += `&assignment_id=${assignmentId}`;
    }
    
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return handleResponse(response);
  },
  
  deleteGradingMemory: async (memoryId) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}/grading-memory/${memoryId}`;
    
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    
    if (!response.ok) {
      const text = await response.text();
      throw new Error(text || 'Failed to delete grading memory');
    }
    
    return { success: true };
  },
  
  // Export grades to Excel file
  exportGrades: async (assignmentId) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}/assignments/${assignmentId}/export-grades`;
    
    // Use fetch with proper authorization to download the file
    return fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      // Extract the filename from the Content-Disposition header
      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = '';
      
      if (contentDisposition && contentDisposition.includes('filename=')) {
        filename = contentDisposition.split('filename=')[1].replace(/["']/g, '').trim();
      }
      
      return response.blob().then(blob => ({ blob, filename }));
    })
    .then(({ blob, filename }) => {
      // Create a blob URL and trigger download
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      
      // Use the filename from the header, or fall back to default if not available
      a.download = filename || `notes_${assignmentId}.xlsx`;
      
      // Trigger the download
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(blobUrl);
      
      return { success: true };
    });
  },
  
  // Upload exam document for LLM context
  uploadExamDocument: async (assignmentId, file) => {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}${API_VERSION}/assignments/${assignmentId}/upload-exam-document`;
    
    const formData = new FormData();
    formData.append('file', file);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        // Don't set Content-Type here, it will be set automatically with FormData
      },
      body: formData,
    });
    
    return handleResponse(response);
  },
};

export default api;
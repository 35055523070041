/*
<ai_context>
  dateUtils.js
  and other utilities. We'll add a `formatGrade` function to handle points or percentage.
</ai_context>
*/

import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs) {
  return twMerge(clsx(inputs))
} 

/**
 * Format a grade for display given the grading_mode and max_points
 * @param {number} grade
 * @param {string} gradingMode
 * @param {number} maxPoints
 * @returns {string}
 */
export function formatGrade(grade, gradingMode = 'percentage', maxPoints = 1) {
  if (gradingMode === 'points') {
    return `${grade}/${maxPoints}`
  }
  // default "percentage" means fraction 0..1
  return `${(grade * 100).toFixed(0)}%`
}
/*
<ai_context>
AssignmentDetailPage.jsx
We must fix the code for "handleDeleteAssignment" vs "deleteSubmission" usage for a single submission. 
If we have a "Delete submission" button, it must call api.deleteSubmission(assignmentId, studentEmail).
We won't do it automatically here; 
But if there's a reference, let's fix or remove it.
</ai_context>
*/
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../services/api";
import { Card } from "../components/ui/card";
import { Badge } from "../components/ui/badge";
import { Alert, AlertDescription } from "../components/ui/alert";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import GoogleFormGradingModal from "../components/GoogleFormGradingModal";
import GoogleClassroomSyncModal from "../components/GoogleClassroomSyncModal";
import SubmissionList from "../components/SubmissionList";
import { Button } from "../components/ui/button";
import FeedbackDocModal from "../components/FeedbackDocModal";
import { Download, FileText } from 'lucide-react';
import { forceUtcThenConvertToMontreal } from '../lib/dateUtils';
function AssignmentDetailPage() {
  const { assignmentId } = useParams();
  const navigate = useNavigate();
  const [loadingAssignment, setLoadingAssignment] = useState(false);
  const [assignment, setAssignment] = useState(null);
  const [error, setError] = useState("");
  const [hasSubmissions, setHasSubmissions] = useState(true);
  const [submissionCount, setSubmissionCount] = useState(0);

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showFormGradingModal, setShowFormGradingModal] = useState(false);
  const [showFeedbackDocsModal, setShowFeedbackDocsModal] = useState(false);

  const fetchAssignment = async () => {
    setLoadingAssignment(true);
    setError("");
    try {
      const data = await api.getAssignment(assignmentId);
      setAssignment(data);
    } catch (err) {
      console.error("Error fetching assignment:", err);
      setError(err.message);
    } finally {
      setLoadingAssignment(false);
    }
  };

  useEffect(() => {
    if (assignmentId) {
      fetchAssignment();
    }
  }, [assignmentId]);

  const handleDeleteAssignment = async () => {
    if (!window.confirm("Êtes-vous sûr(e) de vouloir supprimer ce devoir ?")) return;
    try {
      await api.deleteAssignment(assignmentId);
      toast.success("Devoir supprimé.");
      window.location.href = "/assignments";
    } catch (err) {
      console.error("Error deleting assignment:", err);
      toast.error(err.message);
    }
  };


  const handleSyncDone = () => {
    setShowSyncModal(false);
    toast.success("Synchronisation terminée.");
    fetchAssignment();
  };

  const handleFormGradingDone = () => {
    setShowFormGradingModal(false);
    toast.success("Form corrections terminées.");
    fetchAssignment();
  };

  const handleAttachDocsDone = () => {
    setShowFeedbackDocsModal(false);
    toast.success("Envoi des documents de feedback terminé.");
    fetchAssignment();
  };
  
  const handleExportGrades = async () => {
    try {
      await api.exportGrades(assignmentId);
      toast.success("Téléchargement des notes en cours...");
    } catch (err) {
      console.error("Error exporting grades:", err);
      toast.error("Erreur lors du téléchargement des notes: " + err.message);
    }
  };

  return (
    <div className="container mx-auto py-8 space-y-6 px-4 sm:px-6">
      {error && (
        <Alert variant="destructive" className="mb-6">
          <AlertDescription>
            <strong>Erreur : </strong>
            <span>{error}</span>
          </AlertDescription>
        </Alert>
      )}

      {loadingAssignment && (
        <div className="flex flex-col items-center justify-center p-12 border rounded-lg bg-card shadow-sm">
          <Spinner />
          <em className="mt-4 text-muted-foreground">Chargement du devoir...</em>
        </div>
      )}

      {!loadingAssignment && assignment && (
        <>
          <Card className="shadow-sm overflow-hidden border border-border">
            <div className="p-6">
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4">
                <div>
                  <h1 className="text-2xl font-bold text-foreground">{assignment.filename}</h1>
                  <div className="flex flex-wrap items-center gap-2 mt-2">
                    <span className="text-muted-foreground font-medium">
                      {forceUtcThenConvertToMontreal(assignment.created_at)}
                    </span>
                    {assignment.google_course_id && (
                      <Badge variant="secondary" className="text-primary bg-primary/10 hover:bg-primary/20">
                        Google Classroom
                      </Badge>
                    )}
                  </div>
                </div>
                <div className="flex gap-2 mt-2 sm:mt-0">
                  <Button
                    variant="outline"
                    onClick={() => navigate(`/grade/${assignment.id}/questions`)}
                    className="border-primary text-primary hover:bg-primary/10"
                  >
                    Gérer les questions
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={handleDeleteAssignment}
                    size="sm"
                  >
                    Supprimer
                  </Button>
                </div>
              </div>
            </div>
          </Card>

          <Card className="shadow-sm overflow-hidden">
            <div className="bg-gradient-to-r from-violet-200 to-violet-200 text-primary-foreground p-4">
              <div className="flex justify-end mb-3">
                <Button
                  variant="outline"
                  size="sm"
                  className="bg-white text-primary hover:bg-white/90 hover:text-primary flex items-center gap-1.5"
                  onClick={() => handleExportGrades()}
                  title="Télécharger les notes au format CSV"
                >
                  <Download size={16} />
                  <span>Télécharger les notes</span>
                </Button>
              </div>
              
              <div className="flex flex-col sm:flex-row justify-between gap-3 mt-2">
                <div className="flex flex-wrap gap-2">
                  {assignment.google_course_id && assignment.google_coursework_id && (
                    <Button
                      variant="secondary"
                      size="sm"
                      className={`flex items-center gap-1.5 ${!hasSubmissions ? "animate-pulse shadow-md" : ""}`}
                      onClick={() => setShowFormGradingModal(true)}
                      title="Corriger les réponses des élèves automatiquement"
                    >
                      <img src="/group-1.svg" width={16} alt="Corrige" className="mr-1" />
                      <span>Corriger</span>
                    </Button>
                  )}
                  
                  <Button
                    variant="secondary" 
                    size="sm"
                    className="hidden" // Hide this button but keep it for compatibility
                    onClick={() => setShowSyncModal(true)}
                  >
                    Synchroniser les notes
                  </Button>
                </div>
                
                <Button
                  variant="secondary"
                  size="sm"
                  className="flex items-center gap-1.5"
                  onClick={() => setShowFeedbackDocsModal(true)}
                  title="Créer et envoyer des documents Google Doc avec les notes et commentaires par email aux élèves"
                >
                  <FileText size={16} />
                  <span>Envoyer les notes par email</span>
                </Button>
              </div>
            </div>
            <div className="bg-white pt-6 px-4 pb-4">
              <SubmissionList 
                assignmentId={assignment.id}
                onSubmissionsLoaded={(count) => {
                  setHasSubmissions(count > 0);
                  setSubmissionCount(count);
                }}
              />
            </div>
          </Card>
        </>
      )}

      {showSyncModal && assignment && (
        <GoogleClassroomSyncModal
          assignment={assignment}
          onClose={() => setShowSyncModal(false)}
          onDone={handleSyncDone}
        />
      )}

      {showFormGradingModal && assignment && (
        <GoogleFormGradingModal
          assignment={assignment}
          onClose={() => setShowFormGradingModal(false)}
          onDone={handleFormGradingDone}
        />
      )}

      {showFeedbackDocsModal && assignment && (
        <FeedbackDocModal
          assignment={assignment}
          onClose={() => setShowFeedbackDocsModal(false)}
          onDone={handleAttachDocsDone}
        />
      )}
    </div>
  );
}

export default AssignmentDetailPage;
import React, { createContext, useState, useContext, useCallback } from 'react';
import api from '../services/api';

const UserContext = createContext();

export function UserProvider({ children }) {
  const [userCredits, setUserCredits] = useState(null);

  const refreshCredits = useCallback(async () => {
    try {
      const userData = await api.getCurrentUser();
      setUserCredits(userData.credits);
    } catch (err) {
      console.error('Error fetching user credits:', err);
    }
  }, []);

  return (
    <UserContext.Provider value={{ userCredits, setUserCredits, refreshCredits }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
} 
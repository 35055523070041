/*
<ai_context>
FeedbackDocModal.jsx
This modal provides a UI for creating and sharing Google Docs with feedback directly to students.
</ai_context>
*/

import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardTitle, CardContent, CardDescription } from "./ui/card";
import { Button } from "./ui/button";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import api from "../services/api";
import { API_BASE_URL, API_VERSION } from "../services/config";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "./ui/dialog";

function FeedbackDocModal({ assignment, onClose, onDone }) {
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const [courses, setCourses] = useState([]);
  const [gAssignments, setGAssignments] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedAssignmentId, setSelectedAssignmentId] = useState("");
  
  // New state for student selection and preview
  const [submissions, setSubmissions] = useState([]);
  const [selectedStudentEmail, setSelectedStudentEmail] = useState("");
  const [loadingSubmissions, setLoadingSubmissions] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [loadingPreview, setLoadingPreview] = useState(false);

  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingAssignments, setLoadingAssignments] = useState(false);
  const [attaching, setAttaching] = useState(false);
  const [error, setError] = useState("");

  const popupRef = useRef(null);
  const popupCheckIntervalRef = useRef(null);

  // No longer using Google Classroom integration
  const hasStoredIds = true; // Set to true to skip course/assignment selection

  const ALLOWED_ORIGINS = [
    "http://localhost:3000",
    "http://127.0.0.1:3000",
    "http://localhost:5173",
    "http://127.0.0.1:5173",
    "http://localhost:8000",
    "http://127.0.0.1:8000",
    "https://api.corrige.ca",
    "https://corrige.ca",
    "https://app.corrige.ca"
  ];

  useEffect(() => {
    checkAuthorization();
  }, []);

  useEffect(() => {
    if (isAuthorized && assignment?.id) {
      fetchSubmissions();
    }
  }, [isAuthorized, assignment?.id]);

  useEffect(() => {
    if (popupRef.current) {
      popupCheckIntervalRef.current = setInterval(() => {
        if (popupRef.current && popupRef.current.closed) {
          clearInterval(popupCheckIntervalRef.current);
          popupCheckIntervalRef.current = null;
          popupRef.current = null;
          if (!isAuthorized) {
            setIsAuthorizing(false);
          }
        }
      }, 800);
    }
    return () => {
      if (popupCheckIntervalRef.current) {
        clearInterval(popupCheckIntervalRef.current);
      }
    };
  }, [isAuthorized]);

  async function fetchSubmissions() {
    if (!assignment?.id) return;
    
    setLoadingSubmissions(true);
    try {
      const data = await api.getSubmissionsForAssignment(assignment.id);
      setSubmissions(data);
    } catch (err) {
      console.error("Error fetching submissions:", err);
      setError(err.message);
    } finally {
      setLoadingSubmissions(false);
    }
  }

  async function checkAuthorization() {
    const alreadyAuthorized = localStorage.getItem("googleAuthorized") === "true";
    if (alreadyAuthorized) {
      setIsAuthorized(true);
      if (!hasStoredIds) {
        loadCourses();
      }
      return;
    }
    try {
      const me = await api.getCurrentUser();
      if (me.google_access_token) {
        setIsAuthorized(true);
        localStorage.setItem("googleAuthorized", "true");
        if (!hasStoredIds) {
          loadCourses();
        }
      } else {
        openAuthPopup();
      }
    } catch (err) {
      console.error("Error checking authorization:", err);
      setError(err.message);
    }
  }

  function openAuthPopup() {
    if (isAuthorizing) return;
    setIsAuthorizing(true);

    api
      .getGoogleClassroomConnectUrl()
      .then((data) => {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No local auth token found. Please log in again.");
        }
        const urlWithState = `${data.auth_url}&state=${encodeURIComponent(token)}`;
        popupRef.current = window.open(
          urlWithState,
          "_blank",
          "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600"
        );
        window.addEventListener("message", handleMessage);
      })
      .catch((err) => {
        console.error("Error opening auth popup:", err);
        setError(err.message);
        setIsAuthorizing(false);
      });
  }

  function handleMessage(event) {
    if (!ALLOWED_ORIGINS.includes(event.origin)) {
      return;
    }
    if (event.data === "google-oauth-success") {
      setIsAuthorized(true);
      setIsAuthorizing(false);
      localStorage.setItem("googleAuthorized", "true");
      if (popupRef.current) {
        popupRef.current.close();
      }
      popupRef.current = null;
      window.removeEventListener("message", handleMessage);
      if (!hasStoredIds) {
        loadCourses();
      }
    }
  }

  async function loadCourses() {
    setLoadingCourses(true);
    setError("");
    try {
      const data = await api.listCourses();
      setCourses(data);
    } catch (err) {
      console.error("Error listing courses:", err);
      setError(err.message);
    } finally {
      setLoadingCourses(false);
    }
  }

  async function handleSelectCourse(courseId) {
    setSelectedCourseId(courseId);
    setGAssignments([]);
    setSelectedAssignmentId("");
    if (!courseId) return;

    setLoadingAssignments(true);
    setError("");
    try {
      const data = await api.listAssignments(courseId);
      setGAssignments(data);
    } catch (err) {
      console.error("Error listing google assignments:", err);
      setError(err.message);
    } finally {
      setLoadingAssignments(false);
    }
  }

  async function handleSelectAssignment(gaid) {
    setSelectedAssignmentId(gaid);
  }

  function handleSelectStudent(email) {
    setSelectedStudentEmail(email);
  }

  async function handlePreviewDoc() {
    if (!assignment?.id || !selectedStudentEmail) {
      toast.error("Veuillez sélectionner un élève.");
      return;
    }

    setLoadingPreview(true);
    setError("");

    try {
      // Use placeholder values for course_id and coursework_id since we're no longer using Google Classroom
      const courseId = "direct_email";
      const courseworkId = "direct_email";

      const url = `${API_BASE_URL}${API_VERSION}/classroom/courses/${courseId}/assignments/${courseworkId}/attach-feedback-docs`;
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ 
          assignment_id: assignment.id,
          student_email: selectedStudentEmail,
          preview_mode: true
        })
      });

      if (!resp.ok) {
        const errorText = await resp.text();
        throw new Error(errorText || "Échec de la génération de l'aperçu.");
      }

      const data = await resp.json();
      setPreviewContent(data.preview || "Aucun aperçu disponible");
      setShowPreview(true);
    } catch (err) {
      console.error("Error generating preview:", err);
      toast.error(err.message || "Échec de la génération de l'aperçu.");
      setError(err.message);
    } finally {
      setLoadingPreview(false);
    }
  }

  async function handleAttachDocs() {
    if (!assignment?.id) {
      toast.error("Aucun devoir local trouvé.");
      return;
    }

    // Use placeholder values for course_id and coursework_id since we're no longer using Google Classroom
    const courseId = "direct_email";
    const courseworkId = "direct_email";

    setAttaching(true);
    setError("");

    try {
      const url = `${API_BASE_URL}${API_VERSION}/classroom/courses/${courseId}/assignments/${courseworkId}/attach-feedback-docs`;
      const payload = { assignment_id: assignment.id };
      
      // Add student_email if one is selected
      if (selectedStudentEmail) {
        payload.student_email = selectedStudentEmail;
      }
      
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      });
      
      const txt = await resp.text();
      if (!resp.ok) {
        throw new Error(txt || "Échec de l'envoi du document de feedback.");
      }
      
      const data = JSON.parse(txt);
      toast.success(data.message || "Documents de feedback envoyés avec succès.");
      if (onDone) onDone();
    } catch (err) {
      console.error("Error sending feedback docs:", err);
      toast.error(err.message || "Échec de l'envoi du document de feedback.");
      setError(err.message);
    } finally {
      setAttaching(false);
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="bg-white w-full max-w-2xl p-4 rounded shadow-md">
        <div className="flex justify-between items-center mb-2">
          <div>
            <h2 className="text-lg font-semibold">Envoyer les notes par email</h2>
            <p className="text-sm text-muted-foreground">
              Créez et partagez des documents Google Doc avec les retours détaillés aux élèves
            </p>
          </div>
          <Button variant="ghost" onClick={onClose}>Fermer</Button>
        </div>

        {error && (
          <div className="mb-2 bg-destructive/20 text-destructive p-2 rounded text-sm">
            {error}
          </div>
        )}

        {!isAuthorized && isAuthorizing && (
          <div className="py-4 flex items-center justify-center">
            <Spinner />
            <span className="ml-2 text-sm">Autorisation avec Google...</span>
          </div>
        )}

        {isAuthorized && (
          <>
            {/* Course and assignment selection removed as we no longer use Google Classroom */}

            <Card className="mb-4">
              <CardHeader>
                <CardTitle>Sélection de l'élève</CardTitle>
                <CardDescription>
                  Cette fonctionnalité créera un document Google Doc privé avec les retours détaillés et l'enverra directement par email.
                  Le document sera partagé exclusivement avec l'élève sélectionné (personne d'autre ne pourra y accéder).
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col gap-3">
                  {loadingSubmissions ? (
                    <div className="flex items-center py-2">
                      <Spinner />
                      <span className="ml-2 text-sm">Chargement des élèves...</span>
                    </div>
                  ) : (
                    <>
                      <div>
                        <label className="block text-sm font-medium mb-1">Élève</label>
                        <select
                          value={selectedStudentEmail}
                          onChange={(e) => handleSelectStudent(e.target.value)}
                          className="border border-gray-300 rounded px-2 py-1 w-full"
                        >
                          <option value="">-- Tous les élèves --</option>
                          {submissions.map((sub) => (
                            <option key={sub.student_email} value={sub.student_email}>
                              {sub.student_name ? `${sub.student_name} (${sub.student_email})` : sub.student_email}
                            </option>
                          ))}
                        </select>
                      </div>

                      {selectedStudentEmail && (
                        <>
                          <div className="bg-blue-50 p-3 rounded-md border border-blue-200 text-sm text-blue-800 mt-2">
                            <p className="font-medium">Détails du document à envoyer:</p>
                            <ul className="list-disc ml-5 mt-1 space-y-1">
                              <li>Un Google Doc privé sera créé et partagé uniquement avec <span className="font-semibold">{selectedStudentEmail}</span></li>
                              <li>Le document contiendra les notes et commentaires détaillés pour chaque question</li>
                              <li>L'élève recevra un email l'informant qu'un document a été partagé avec lui</li>
                              <li>Vous (l'enseignant) resterez le propriétaire du document</li>
                              <li>Aucune intégration avec Google Classroom n'est nécessaire</li>
                            </ul>
                          </div>
                          
                          <div className="flex justify-start mt-2">
                            <Button
                              variant="outline"
                              onClick={handlePreviewDoc}
                              disabled={loadingPreview}
                              className="mr-2"
                            >
                              {loadingPreview ? (
                                <>
                                  <Spinner className="mr-2 h-4 w-4" />
                                  Génération de l'aperçu...
                                </>
                              ) : (
                                "Aperçu du document"
                              )}
                            </Button>
                          </div>
                        </>
                      )}

                      {!selectedStudentEmail && (
                        <div className="bg-amber-50 p-3 rounded-md border border-amber-200 text-sm text-amber-800 mt-2">
                          <p className="font-medium">Envoi à tous les élèves:</p>
                          <ul className="list-disc ml-5 mt-1 space-y-1">
                            <li>Un Google Doc privé individuel sera créé pour chaque élève</li>
                            <li>Chaque document contiendra les notes et commentaires personnalisés pour cet élève uniquement</li>
                            <li>Chaque document sera partagé exclusivement avec l'élève concerné</li>
                            <li>Les élèves recevront un email les informant qu'un document a été partagé avec eux</li>
                            <li>Cette opération peut prendre du temps selon le nombre d'élèves</li>
                          </ul>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </CardContent>
            </Card>

            <div className="mt-6 flex justify-end">
              <Button
                onClick={handleAttachDocs}
                disabled={attaching}
              >
                {attaching ? (
                  <>
                    <Spinner className="mr-2 h-4 w-4" />
                    Envoi en cours...
                  </>
                ) : (
                  selectedStudentEmail ? 
                  `Créer et envoyer le Google Doc à ${submissions.find(s => s.student_email === selectedStudentEmail)?.student_name || selectedStudentEmail}` : 
                  "Créer et envoyer les Google Docs à tous les élèves"
                )}
              </Button>
            </div>
          </>
        )}

        {/* Preview Modal */}
        <Dialog open={showPreview} onOpenChange={setShowPreview}>
          <DialogContent className="sm:max-w-2xl">
            <DialogHeader>
              <DialogTitle>Aperçu du document de feedback</DialogTitle>
              <DialogDescription>
                Voici à quoi ressemblera le document Google Doc qui sera créé et partagé uniquement avec l'élève sélectionné.
                L'élève recevra une notification par email l'informant que ce document a été partagé avec lui.
              </DialogDescription>
            </DialogHeader>
            <div className="bg-muted/20 p-4 rounded border max-h-96 overflow-auto whitespace-pre-wrap font-mono text-sm">
              {previewContent}
            </div>
            <div className="mt-2 bg-blue-50 p-2 rounded-md border border-blue-200 text-xs text-blue-800">
              <p>Ce document sera formaté et structuré dans Google Docs pour une meilleure lisibilité.</p>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default FeedbackDocModal;
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import api from '../../services/api';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '../../components/ui/table';
import { Button } from '../../components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogDescription } from '../../components/ui/dialog';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../components/ui/card';
import { Pagination, PaginationContent, PaginationEllipsis, PaginationItem, PaginationLink, PaginationNext, PaginationPrevious } from '../../components/ui/pagination';
import { AlertTriangle, Trash2 } from "lucide-react";
import { forceUtcThenConvertToMontreal } from '../../lib/dateUtils';

function GradingMemoryPage() {
  const [memories, setMemories] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [deleteDialog, setDeleteDialog] = useState({ open: false, memoryId: null });
  const limit = 20;

  const fetchMemories = async () => {
    setLoading(true);
    try {
      const response = await api.getGradingMemories({
        skip: (page - 1) * limit,
        limit
      });
      
      setMemories(response.items);
      setTotal(response.total);
      setError(null);
    } catch (err) {
      console.error('Error fetching grading memories:', err);
      setError('Failed to load your grading memories. Please try again later.');
      toast.error('Failed to load grading memories.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemories();
  }, [page]);

  const handleDelete = async (memoryId) => {
    try {
      await api.deleteGradingMemory(memoryId);
      toast.success('Grading memory deleted successfully.');
      fetchMemories(); // Refresh the list
    } catch (err) {
      console.error('Error deleting memory:', err);
      toast.error('Failed to delete grading memory.');
    } finally {
      setDeleteDialog({ open: false, memoryId: null });
    }
  };

  const openDeleteDialog = (memoryId) => {
    setDeleteDialog({ open: true, memoryId });
  };

  const closeDeleteDialog = () => {
    setDeleteDialog({ open: false, memoryId: null });
  };

  const totalPages = Math.ceil(total / limit);

  // Using the existing date formatter from utils

  return (
    <div className="container mx-auto p-4">
      <Card>
        <CardHeader>
          <CardTitle>Mémoire de Correction</CardTitle>
          <CardDescription>
            Consultez vos corrections précédentes. Ces données aident l'IA à comprendre votre style de correction.
          </CardDescription>
        </CardHeader>
        <CardContent>
          {loading ? (
            <div className="flex justify-center p-8">
              <Spinner />
            </div>
          ) : error ? (
            <div className="flex items-center gap-2 text-destructive border border-destructive/50 p-4 rounded-md">
              <AlertTriangle size={20} />
              <p>{error}</p>
            </div>
          ) : memories.length === 0 ? (
            <div className="text-center p-8 text-muted-foreground">
              <p>Aucune mémoire de correction trouvée.</p>
              <p className="text-sm mt-2">
                Les mémoires sont créées lorsque vous modifiez manuellement la note d'un élève.
              </p>
            </div>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="font-semibold">Question</TableHead>
                    <TableHead className="font-semibold">Réponse Correcte</TableHead>
                    <TableHead className="font-semibold">Réponse Élève</TableHead>
                    <TableHead className="font-semibold">Note Avant</TableHead>
                    <TableHead className="font-semibold">Note Après</TableHead>
                    <TableHead className="font-semibold">Explication</TableHead>
                    <TableHead className="font-semibold">Date</TableHead>
                    <TableHead className="font-semibold w-20">Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {memories.map((memory) => (
                    <TableRow key={memory.id} className="hover:bg-muted/20">
                      <TableCell className="max-w-[200px] truncate">
                        <div className="max-h-[150px] overflow-y-auto">
                          {memory.question_text}
                        </div>
                      </TableCell>
                      <TableCell className="max-w-[200px]">
                        <div className="max-h-[150px] overflow-y-auto">
                          {memory.correct_answer}
                        </div>
                      </TableCell>
                      <TableCell className="max-w-[200px]">
                        <div className="max-h-[150px] overflow-y-auto">
                          {memory.student_answer}
                        </div>
                      </TableCell>
                      <TableCell>
                        {memory.old_grade !== null
                          ? `${memory.old_grade}/${memory.max_points}`
                          : "—"}
                      </TableCell>
                      <TableCell>
                        {memory.new_grade}/{memory.max_points}
                      </TableCell>
                      <TableCell className="max-w-[200px]">
                        <div className="max-h-[150px] overflow-y-auto">
                          {memory.explanation || '—'}
                        </div>
                      </TableCell>
                      <TableCell>{forceUtcThenConvertToMontreal(memory.created_at)}</TableCell>
                      <TableCell>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => openDeleteDialog(memory.id)}
                          className="text-destructive hover:text-destructive/90 hover:bg-destructive/10"
                        >
                          <Trash2 size={18} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {totalPages > 1 && (
                <div className="mt-4">
                  <Pagination>
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious 
                          onClick={() => setPage(p => Math.max(1, p - 1))}
                          disabled={page === 1}
                        />
                      </PaginationItem>
                      
                      {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                        // Show current page and surrounding pages
                        let pageToShow;
                        if (totalPages <= 5) {
                          pageToShow = i + 1;
                        } else if (page <= 3) {
                          pageToShow = i + 1;
                        } else if (page >= totalPages - 2) {
                          pageToShow = totalPages - 4 + i;
                        } else {
                          pageToShow = page - 2 + i;
                        }
                        
                        return (
                          <PaginationItem key={i}>
                            <PaginationLink
                              isActive={pageToShow === page}
                              onClick={() => setPage(pageToShow)}
                            >
                              {pageToShow}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      })}
                      
                      {totalPages > 5 && page < totalPages - 2 && (
                        <PaginationItem>
                          <PaginationEllipsis />
                        </PaginationItem>
                      )}
                      
                      <PaginationItem>
                        <PaginationNext 
                          onClick={() => setPage(p => Math.min(totalPages, p + 1))}
                          disabled={page === totalPages}
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                </div>
              )}
            </>
          )}
        </CardContent>
      </Card>

      <Dialog open={deleteDialog.open} onOpenChange={closeDeleteDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Supprimer cette mémoire de correction?</DialogTitle>
            <DialogDescription>
              Cette action ne peut pas être annulée. Cette mémoire sera supprimée définitivement.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={closeDeleteDialog}>
              Annuler
            </Button>
            <Button 
              variant="destructive" 
              onClick={() => handleDelete(deleteDialog.memoryId)}
            >
              Supprimer
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default GradingMemoryPage;
import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card'; // Use Card for structure

function PrivacyPolicy() {
  const lastUpdated = "28 Mars 2025"; // Update this date when the policy changes

  return (
    <div className="container mx-auto max-w-4xl py-12 px-4 sm:px-6 lg:px-8">
      <Card className="bg-card text-card-foreground shadow-lg border border-border">
        <CardHeader className="border-b border-border">
          <CardTitle className="text-3xl font-bold text-center text-primary">
            Politique de Confidentialité de Corrige
          </CardTitle>
          <p className="text-center text-sm text-muted-foreground mt-2">
            Dernière mise à jour : {lastUpdated}
          </p>
        </CardHeader>
        <CardContent className="pt-8 space-y-8 text-base leading-relaxed"> {/* Increased space-y */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
            <p>
              Votre vie privée est importante pour nous chez Corrige (« Corrige », « nous », « notre »), tout comme l'est la transparence sur la manière dont nous collectons, utilisons et partageons les informations vous concernant. Cette Politique de Confidentialité explique nos pratiques en matière de confidentialité pour les activités décrites ici. Veuillez lire attentivement cette politique pour comprendre comment nous traitons vos informations personnelles lorsque vous accédez à notre site web ou utilisez nos services (« Services »).
            </p>
            <p className="mt-3">
              En utilisant notre site web ou nos Services, vous acceptez les termes de cette Politique de Confidentialité. Nous pourrons mettre à jour cette politique de temps à autre. En continuant à utiliser nos Services après toute modification, vous serez réputé avoir accepté ces changements. Il est donc important de consulter régulièrement cette page.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Types d'informations que nous collectons</h2>
            <p className="mb-3">
              Nous collectons uniquement les informations nécessaires pour fournir et améliorer nos Services. Cela inclut :
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Informations de compte :</strong> Votre nom et adresse e-mail, fournis lors de l'inscription ou via l'authentification Google.</li>
              <li>
                <strong>Données Google (avec votre autorisation via OAuth) :</strong>
                <ul className="list-circle pl-6 mt-2 space-y-1">
                  <li><strong>Profil Google :</strong> Informations de base (nom, e-mail) pour l'authentification.</li>
                  <li><strong>Google Classroom :</strong> Liste de vos cours, détails des devoirs (titre, description, pièces jointes incluant les liens Google Forms), liste d'élèves (noms, e-mails), et état des soumissions.</li>
                  <li><strong>Google Forms :</strong> Structure des questions (texte, choix, points) et réponses des élèves.</li>
                  <li><strong>Google Drive :</strong> Utilisé <strong>uniquement</strong> pour créer des documents Google Docs contenant les retours de correction, y écrire le contenu, et gérer les permissions de partage (exclusivement avec l'élève concerné). Nous n'accédons pas à d'autres fichiers.</li>
                </ul>
              </li>
              <li><strong>Données de Devoir et de Correction :</strong> Fichiers d'examens ou texte d'examen (si téléversé par vous), corrigés que vous fournissez, réponses des élèves, notes (proposées par l'IA et finales), justifications, et vos corrections ou préférences de notation.</li>
              <li><strong>Données d'Utilisation :</strong> Informations générales sur la façon dont vous interagissez avec nos Services (pour l'amélioration).</li>
            </ul>
            <p className="mt-3">
              Il est important de noter que toutes les données de devoir et de correction que vous téléversez ou générez via notre plateforme restent votre propriété. Nous agissons uniquement en tant que processeur de ces informations pour vous fournir nos Services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Comment nous utilisons vos informations</h2>
            <p className="mb-3">
              Vos informations sont utilisées <strong>exclusivement</strong> pour :
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Fournir, maintenir et améliorer les fonctionnalités de Corrige.</li>
              <li>Authentifier votre accès à nos Services.</li>
              <li>Permettre l'intégration avec Google Classroom, Google Forms et Google Drive pour importer/exporter des données de devoirs, de soumissions, et de retours.</li>
              <li>Analyser les réponses des élèves à l'aide de l'IA pour générer des propositions de notes et de justifications, en se basant sur le contexte fourni (question, corrigé, réponse élève, contexte de l'examen) et <strong>les préférences de notation dérivées de vos corrections antérieures</strong>.</li>
              <li>Créer des documents Google Docs contenant des retours détaillés et les partager de manière sécurisée et privée avec chaque élève concerné.</li>
              <li><strong>Personnaliser les suggestions de l'IA</strong> pour mieux correspondre à votre style de correction, en s'appuyant sur vos ajustements précédents.</li>
              <li>Communiquer avec vous concernant le service (mises à jour, support).</li>
              <li>Analyser l'utilisation agrégée et anonymisée pour améliorer nos Services.</li>
            </ul>
            <p className="mt-3 font-medium">
              Nous n'utilisons <strong>jamais</strong> les données des élèves ou vos corrigés pour entraîner des modèles d'IA généraux. La personnalisation de l'IA est spécifique à votre compte.
            </p>
            <p className="mt-3">
              L'utilisation par Corrige des informations reçues des APIs Google respectera la <a href="https://developers.google.com/terms/api-services-user-data-policy#limited_use_requirements" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">Politique de Données Utilisateur des Services API Google</a>, y compris les exigences d'Utilisation Limitée.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Partage et Divulgation des informations</h2>
            <p className="mb-3">
              Nous ne vendons, ne louons, ni ne partageons vos informations personnelles ou les données des élèves avec des tiers non autorisés. Les exceptions sont :
            </p>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Google :</strong> Pour l'authentification et les interactions API que vous autorisez.</li>
              <li><strong>OpenAI :</strong> Transmission sécurisée des textes de questions, corrigés, et réponses élèves (<strong>ainsi que le contexte pertinent si nécessaire</strong>) pour obtenir les suggestions de correction. Les données transmises sont soumises à la politique de confidentialité d'OpenAI et ne sont pas utilisées pour l'entraînement de leurs modèles.</li>
              <li><strong>Stripe :</strong> Pour le traitement sécurisé des paiements.</li>
              <li><strong>Fournisseurs d'infrastructure :</strong> Nous utilisons des services cloud pour héberger l'application. Ces fournisseurs n'ont pas accès aux données spécifiques de vos corrections.</li>
              <li><strong>Obligations légales :</strong> Si la loi l'exige.</li>
            </ul>
            <p className="mt-3">
              Les documents de feedback générés sur Google Drive sont partagés <strong>exclusivement</strong> avec l'élève concerné.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Sécurité des données</h2>
            <p>
              La sécurité de vos données est une priorité absolue. Nous mettons en œuvre des mesures de sécurité conformes aux standards de l'industrie, telles que le chiffrement des données en transit et au repos, ainsi que des contrôles d'accès stricts pour protéger vos informations contre tout accès, modification ou divulgation non autorisés. Les informations sensibles comme les jetons d'accès sont stockées de manière sécurisée. Nous nous engageons à maintenir et améliorer continuellement nos pratiques de sécurité.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Conservation et Suppression des données</h2>
            <p>
              Nous conservons vos informations de compte et de correction tant que votre compte est actif ou nécessaire pour fournir les Services. <strong>Certaines données de correction peuvent être conservées</strong> pour maintenir la personnalisation de l'IA pour votre compte. Vous pouvez demander la suppression complète de votre compte et de toutes les données associées en nous contactant. Les données seront supprimées de nos systèmes actifs conformément à nos procédures internes et aux exigences légales.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Vos droits</h2>
            <p className="mb-3">Concernant vos informations personnelles, vous avez le droit de :</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Demander l'accès aux informations que nous détenons.</li>
              <li>Demander la correction d'informations inexactes.</li>
              <li>Demander la suppression de votre compte et des données associées.</li>
              <li>Gérer ou révoquer les autorisations accordées à Corrige via les paramètres de sécurité de votre compte Google.</li>
            </ul>
            <p className="mt-3">Pour exercer ces droits (sauf la révocation Google), veuillez nous contacter à l'adresse indiquée ci-dessous.</p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Mises à jour de la politique</h2>
            <p>
              Cette politique peut être mise à jour. Nous vous informerons des changements importants via notre site web ou par e-mail.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4">Contactez-nous</h2>
            <p>
              Pour toute question ou préoccupation concernant la confidentialité, veuillez contacter :
            </p>
            <p className="mt-2">
              <strong>Équipe Corrige</strong><br />
              Email : <a href="mailto:support@corrige.ca" className="text-primary hover:underline">support@corrige.ca</a>
            </p>
          </section>
        </CardContent>
      </Card>
    </div>
  );
}

export default PrivacyPolicy; 
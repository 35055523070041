// PublicRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';

function PublicRoute({ children }) {
  const token = localStorage.getItem('token');

  // If there's a token, user is logged in. Redirect them away!
  if (token) {
    return <Navigate to="/assignments" replace />;
  }

  // Otherwise, allow them to see the children (e.g. the login/register page).
  return children;
}

export default PublicRoute;

/*
<ai_context>
GoogleFormAssignmentCreatorModal.jsx
We add navigation to the questions page after successful assignment creation.
Previously GoogleFormSolutionCreatorModal.jsx
Renamed "solution" => "assignment"
User text from "Solution" => "Devoir".
We now include course_id, coursework_id in the POST /assignments/google-form body
</ai_context>
*/

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import api from "../services/api";
import { API_BASE_URL, API_VERSION } from "../services/config";

function GoogleFormAssignmentCreatorModal({ onClose, onSuccess }) {
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const [courses, setCourses] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedAssignmentId, setSelectedAssignmentId] = useState("");
  const [formUrl, setFormUrl] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [error, setError] = useState("");

  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingAssignments, setLoadingAssignments] = useState(false);
  const [creatingAssignment, setCreatingAssignment] = useState(false);

  const popupRef = useRef(null);
  const popupCheckIntervalRef = useRef(null);
  const navigate = useNavigate();

  const ALLOWED_ORIGINS = [
    "http://localhost:3000",
    "http://127.0.0.1:3000",
    "http://localhost:5173",
    "http://127.0.0.1:5173",
    "http://localhost:8000",
    "http://127.0.0.1:8000",
    "https://api.corrige.ca",
    "https://corrige.ca",
    "https://app.corrige.ca",
  ];

  useEffect(() => {
    checkAuthorization();
  }, []);

  useEffect(() => {
    if (popupRef.current) {
      popupCheckIntervalRef.current = setInterval(() => {
        if (popupRef.current && popupRef.current.closed) {
          clearInterval(popupCheckIntervalRef.current);
          popupCheckIntervalRef.current = null;
          popupRef.current = null;
          if (!isAuthorized) {
            setIsAuthorizing(false);
          }
        }
      }, 800);
    }
    return () => {
      if (popupCheckIntervalRef.current) {
        clearInterval(popupCheckIntervalRef.current);
      }
    };
  }, [isAuthorized]);

  async function checkAuthorization() {
    const alreadyAuthorized = localStorage.getItem("googleAuthorized") === "true";
    if (alreadyAuthorized) {
      setIsAuthorized(true);
      loadCourses();
      return;
    }
    try {
      const me = await api.getCurrentUser();
      if (me.google_access_token) {
        setIsAuthorized(true);
        localStorage.setItem("googleAuthorized", "true");
        loadCourses();
      } else {
        openAuthPopup();
      }
    } catch (err) {
      console.error("Error checking google auth:", err);
      setError(err.message);
    }
  }

  function openAuthPopup() {
    if (isAuthorizing) return;
    setIsAuthorizing(true);
    api
      .getGoogleClassroomConnectUrl()
      .then((data) => {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No local auth token found. Please login again.");
        }
        const urlWithState = `${data.auth_url}&state=${encodeURIComponent(token)}`;
        popupRef.current = window.open(
          urlWithState,
          "_blank",
          "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600"
        );
        window.addEventListener("message", handleMessage);
      })
      .catch((err) => {
        console.error("Error opening auth popup:", err);
        setError(err.message);
        setIsAuthorizing(false);
      });
  }

  function handleMessage(event) {
    if (!ALLOWED_ORIGINS.includes(event.origin)) {
      return;
    }
    if (event.data === "google-oauth-success") {
      setIsAuthorized(true);
      setIsAuthorizing(false);
      localStorage.setItem("googleAuthorized", "true");
      if (popupRef.current) {
        popupRef.current.close();
      }
      popupRef.current = null;
      window.removeEventListener("message", handleMessage);
      loadCourses();
    }
  }

  async function loadCourses() {
    setLoadingCourses(true);
    setError("");
    try {
      const data = await api.listCourses();
      setCourses(data);
    } catch (err) {
      console.error("Error listing courses:", err);
      
      // Check if error is related to Google Auth token expiration
      if (err.status === 401 && err.url && err.url.includes("/classroom/")) {
        console.log("401 error detected related to Google during course listing. Triggering reauthorization.");
        setIsAuthorized(false);
        localStorage.removeItem("googleAuthorized");
        openAuthPopup();
      } else {
        setError(err.message);
      }
    } finally {
      setLoadingCourses(false);
    }
  }

  async function handleSelectCourse(courseId) {
    setSelectedCourseId(courseId);
    setAssignments([]);
    setSelectedAssignmentId("");
    setFormUrl("");
    setFormTitle("");
    if (!courseId) return;

    setLoadingAssignments(true);
    setError("");
    try {
      const data = await api.listAssignments(courseId);
      setAssignments(data);
    } catch (err) {
      console.error("Error listing assignments:", err);
      
      // Check if error is related to Google Auth token expiration
      if (err.status === 401 && err.url && err.url.includes("/classroom/")) {
        console.log("401 error detected related to Google during assignment listing. Triggering reauthorization.");
        setIsAuthorized(false);
        localStorage.removeItem("googleAuthorized");
        openAuthPopup();
      } else {
        setError(err.message);
      }
    } finally {
      setLoadingAssignments(false);
    }
  }

  function handleSelectAssignment(aid) {
    setSelectedAssignmentId(aid);
    setFormUrl("");
    setFormTitle("");
    if (!aid) return;

    const found = assignments.find((a) => a.id === aid);
    if (!found) return;
    const materials = found.materials || [];
    const formMaterial = materials.find((m) => m.form);
    if (formMaterial && formMaterial.form.formUrl) {
      setFormUrl(formMaterial.form.formUrl);
      setFormTitle(formMaterial.form.title || "");
    }
  }

  async function handleCreateAssignment() {
    if (!formUrl) {
      toast.error("Aucun Google Form trouvé dans ce devoir.");
      return;
    }
    let formId = "";
    try {
      const splitted = formUrl.split("/d/");
      if (splitted.length < 2) {
        throw new Error("Impossible d'extraire l'ID du formulaire");
      }
      formId = splitted[1].split("/")[0];
    } catch (err) {
      toast.error(err.message || "Impossible d'extraire l'ID du formulaire");
      return;
    }

    if (!selectedCourseId || !selectedAssignmentId) {
      toast.error("Veuillez sélectionner un cours et un devoir google classroom.");
      return;
    }

    setCreatingAssignment(true);
    setError("");

    // Get course name for selected course
    const selectedCourse = courses.find(c => c.id === selectedCourseId);
    const courseName = selectedCourse ? selectedCourse.name : "";
    
    // Include course name in the form title
    const fullTitle = courseName 
      ? `${courseName} - ${formTitle || `Google Form (${formId})`}` 
      : formTitle || `Google Form (${formId})`;

    try {
      const bodyPayload = {
        course_id: selectedCourseId,
        coursework_id: selectedAssignmentId,
        form_id: formId,
        form_title: fullTitle,
      };

      const response = await fetch(`${API_BASE_URL}${API_VERSION}/assignments/google-form`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyPayload),
      });
      if (!response.ok) {
        const txt = await response.text();
        throw new Error(txt || "Échec de la création du devoir depuis Google Form");
      }
      
      const responseText = await response.text();
      toast.success("Devoir créé avec succès !");
      
      // Extract the new assignment ID and navigate to the questions page
      try {
        const newAssignmentData = JSON.parse(responseText);
        if (newAssignmentData && newAssignmentData.id) {
          const newAssignmentId = newAssignmentData.id;
          toast.info("Redirection vers la gestion des questions...");
          if (onSuccess) onSuccess(); // Call original success handler (closes modal, refreshes list)
          navigate(`/grade/${newAssignmentId}/questions`); // Navigate to the questions page
        } else {
          console.error("Missing ID in assignment creation response:", newAssignmentData);
          toast.error("Devoir créé, mais impossible de récupérer l'ID pour la redirection.");
          if (onSuccess) onSuccess(); // Still call original handler
        }
      } catch (parseError) {
        console.error("Error parsing response:", parseError, responseText);
        toast.error("Devoir créé, mais impossible de traiter la réponse pour la redirection.");
        if (onSuccess) onSuccess(); // Still call original handler
      }
    } catch (err) {
      console.error("Error creating assignment from google form:", err);
      
      // Check if error is related to Google Auth token expiration
      if ((err.message.includes("401") || err.message.includes("Unauthorized")) && err.message.includes("Google")) {
        console.log("Google auth error detected during assignment creation. Triggering reauthorization.");
        setIsAuthorized(false);
        localStorage.removeItem("googleAuthorized");
        openAuthPopup();
      } else {
        toast.error(err.message);
        setError(err.message);
      }
    } finally {
      setCreatingAssignment(false);
    }
  }

  return (
    <div 
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="bg-white w-full max-w-xl p-4 rounded shadow-md">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-semibold">Importer un devoir depuis Google Forms</h2>
          <Button variant="ghost" onClick={onClose}>Fermer</Button>
        </div>

        {error && (
          <div className="mb-2 bg-destructive/20 text-destructive p-2 rounded text-sm">
            {error}
          </div>
        )}

        {!isAuthorized && isAuthorizing && (
          <div className="py-4 flex items-center justify-center">
            <Spinner />
            <span className="ml-2 text-sm">Autorisation avec Google...</span>
          </div>
        )}

        {isAuthorized && (
          <>
            <Card className="mb-4">
              <CardHeader>
                <CardTitle>Sélection du cours et du devoir</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col sm:flex-row gap-4">
                  <div className="flex-1">
                    <label className="block text-sm font-medium">
                      Cours
                      {loadingCourses && <Spinner />}
                    </label>
                    <select
                      value={selectedCourseId}
                      onChange={(e) => handleSelectCourse(e.target.value)}
                      className="mt-1 border border-gray-300 rounded px-2 py-1 w-full"
                    >
                      <option value="">-- Choisir un cours --</option>
                      {courses.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex-1">
                    <label className="block text-sm font-medium">
                      Devoir
                      {loadingAssignments && <Spinner />}
                    </label>
                    <select
                      value={selectedAssignmentId}
                      onChange={(e) => handleSelectAssignment(e.target.value)}
                      className="mt-1 border border-gray-300 rounded px-2 py-1 w-full"
                    >
                      <option value="">-- Choisir un devoir --</option>
                      {assignments.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </CardContent>
            </Card>

            {!!formUrl && (
              <div className="mt-4 bg-muted/20 p-3 rounded">
                <p className="text-sm">
                  <strong>Google Form trouvé:</strong> {formTitle || "(sans nom)"}
                </p>
              </div>
            )}

            <div className="mt-6 flex justify-end">
              <Button
                onClick={handleCreateAssignment}
                disabled={creatingAssignment || !formUrl}
              >
                {creatingAssignment ? "Création en cours..." : "Créer le devoir"}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default GoogleFormAssignmentCreatorModal;
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import api from '../services/api';
import { Button } from '../components/ui/button';
import Spinner from '../components/Spinner';
import { toast } from 'react-toastify';
import { Label } from '../components/ui/label';
import { Textarea } from '../components/ui/textarea';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../components/ui/card';
import { Badge } from '../components/ui/badge';
import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import { ArrowLeft, Save, HistoryIcon, Check, Download, Upload, Info, AlertCircle } from 'lucide-react';
import { ImportCorrectAnswersModal } from '../components/ImportCorrectAnswersModal';
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from '../components/ui/table';
import { Input } from '../components/ui/input';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '../components/ui/breadcrumb';
import { forceUtcThenConvertToMontreal } from '../lib/dateUtils';
import { cn } from '../lib/utils'; // Assuming you have this utility

function AssignmentQuestionsPage() {
  const { assignmentId } = useParams();
  const navigate = useNavigate();

  const [assignment, setAssignment] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [modifiedQuestionIds, setModifiedQuestionIds] = useState(new Set()); // Track modified question IDs

  const [importModalOpen, setImportModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const originalQuestions = useRef([]); // Store initial state

  useEffect(() => {
    if (!assignmentId) return;
    fetchAssignment(assignmentId);
    fetchQuestions(assignmentId);
  }, [assignmentId]);

  useEffect(() => {
    if (saveSuccess) {
      const timer = setTimeout(() => setSaveSuccess(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [saveSuccess]);

  const fetchAssignment = async (id) => {
    try {
      const data = await api.getAssignment(id);
      setAssignment(data);
    } catch (err) {
      console.error('Erreur lors de la récupération du devoir:', err);
      setError(err.message);
    }
  };

  const fetchQuestions = async (id) => {
    setLoading(true);
    setError('');
    try {
      const data = await api.getAssignmentQuestions(id);
      const sortedData = data.sort((a, b) => a.id - b.id); // Ensure consistent order
      setQuestions(sortedData);
      originalQuestions.current = JSON.parse(JSON.stringify(sortedData)); // Deep copy
      setModifiedQuestionIds(new Set()); // Reset modified tracking
    } catch (err) {
      console.error('Erreur lors de la récupération des questions:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Generic handler for changes in questions array
  const handleQuestionChange = useCallback((questionId, field, value) => {
    setQuestions(prev =>
      prev.map(q => {
        if (q.id === questionId) {
          // Track modification
          setModifiedQuestionIds(prevSet => new Set(prevSet).add(questionId));
          return { ...q, [field]: value };
        }
        return q;
      })
    );
  }, []);
  
  // Specific handler for metadata changes (MCQ, Multi-Select)
  const handleMetadataChange = useCallback((questionId, newMetadata) => {
    setQuestions(prev =>
      prev.map(q => {
        if (q.id === questionId) {
          setModifiedQuestionIds(prevSet => new Set(prevSet).add(questionId));
          return { ...q, question_metadata: newMetadata };
        }
        return q;
      })
    );
  }, []);

  // Check if a specific question has been modified
  const isQuestionModified = (questionId) => {
    return modifiedQuestionIds.has(questionId);
  };

  const handleSave = async () => {
    if (!assignmentId) return;
    setSaving(true);
    setError('');

    const questionsToRegrade = [];
    const updates = questions
      .filter(q => modifiedQuestionIds.has(q.id)) // Only send modified questions
      .map(q => {
        const originalQ = originalQuestions.current.find(oq => oq.id === q.id);
        // Check if points changed
        if (originalQ && originalQ.points !== q.points) {
          questionsToRegrade.push(q.id);
        }
        // Return the update payload
        return {
          id: q.id,
          correct_answer: q.correct_answer,
          points: q.points,
          question_type: q.question_type,
          question_metadata: q.question_metadata,
        };
      });

    if (updates.length === 0) {
      toast.info("Aucune modification à enregistrer.");
      setSaving(false);
      return;
    }

    try {
      const updatedData = await api.updateAssignmentQuestions(assignmentId, updates);
      
      // Update local state with potentially re-ordered or changed data from backend
      const sortedUpdatedData = updatedData.sort((a, b) => a.id - b.id);
      setQuestions(sortedUpdatedData);
      originalQuestions.current = JSON.parse(JSON.stringify(sortedUpdatedData)); // Update original state
      setModifiedQuestionIds(new Set()); // Clear modified tracking
      
      setSaveSuccess(true);
      toast.success('Questions mises à jour avec succès');

      // Handle regrading if points changed
      await handleRegradingCheck(questionsToRegrade);

      // Re-add redirection logic
      toast.info('Redirection vers la page des soumissions...');
      setTimeout(() => navigate(`/grade/${assignmentId}`), 1500); // Navigate after 1.5 seconds

    } catch (err) {
      console.error("Erreur lors de l'enregistrement des questions:", err);
      toast.error(err.message);
      setError(err.message);
    } finally {
      setSaving(false);
    }
  };

  const handleRegradingCheck = async (questionsToRegradeIds) => {
    if (questionsToRegradeIds.length === 0) return;

    try {
      const submissions = await api.getSubmissionsByAssignment(assignmentId);
      if (!submissions || submissions.length === 0) return;

      const modifiedQuestionNumbers = questions
        .filter(q => questionsToRegradeIds.includes(q.id))
        .map(q => `Q${q.question_number || '?'}`)
        .join(', ');

      const shouldRegrade = window.confirm(
        `Les points maximum ont été modifiés pour ${modifiedQuestionNumbers}. Voulez-vous recorriger les réponses pour ces questions ? Cela peut prendre un certain temps et modifiera les notes existantes (sauf celles verrouillées).`
      );

      if (shouldRegrade) {
        toast.info(`Recorrection en cours pour ${questionsToRegradeIds.length} question(s)...`);
        let successCount = 0;
        for (const questionId of questionsToRegradeIds) {
          try {
            await api.regradeQuestion(assignmentId, questionId);
            successCount++;
            await new Promise(resolve => setTimeout(resolve, 100)); // Small delay
          } catch (regradeErr) {
            console.error(`Erreur lors de la recorrection de la question ${questionId}:`, regradeErr);
            toast.error(`Échec de la recorrection pour la question ID ${questionId}.`);
          }
        }
        if (successCount > 0) {
           toast.success(`${successCount} question(s) envoyée(s) pour recorrection. Les notes seront mises à jour.`);
        }
      }
    } catch (err) {
      console.error("Erreur lors de la vérification pour la recorrection:", err);
    }
  };

  const handleImportSuccess = async () => {
    await fetchQuestions(assignmentId); // Refresh questions after import
    toast.success('Les réponses correctes ont été importées avec succès.');
  };

  // New function to handle preview of imported answers
  const handleApplyImportedAnswers = useCallback((potentialUpdates) => {
    if (!potentialUpdates || potentialUpdates.length === 0) {
      toast.info("Aucune réponse correcte correspondante trouvée ou à mettre à jour.");
      return;
    }

    let updatedCount = 0;
    const newlyModifiedIds = new Set(); // Track IDs modified by this import

    setQuestions(prevQuestions => {
      // Use map to update questions
      return prevQuestions.map(currentQ => {
        const update = potentialUpdates.find(upd => upd.target_question_id === currentQ.id);
        if (update) {
          let changed = false;
          const updatedQuestion = { ...currentQ }; // Create a new object

          // Apply correct answer if provided and different
          if (update.new_correct_answer !== undefined && update.new_correct_answer !== updatedQuestion.correct_answer) {
            updatedQuestion.correct_answer = update.new_correct_answer;
            changed = true;
          }
          // Apply type if provided and different
          if (update.new_question_type !== undefined && update.new_question_type !== updatedQuestion.question_type) {
            updatedQuestion.question_type = update.new_question_type;
            changed = true;
          }
          // Apply metadata if provided and different
          if (update.new_question_metadata !== undefined && 
              JSON.stringify(update.new_question_metadata) !== JSON.stringify(updatedQuestion.question_metadata)) {
            updatedQuestion.question_metadata = update.new_question_metadata;
            changed = true;
          }

          if (changed) {
            newlyModifiedIds.add(currentQ.id); // Track this modification
            updatedCount++;
            return updatedQuestion; // Return the modified question
          }
        }
        return currentQ; // Return the original question if no update or no change
      });
    });

    // Add the newly modified IDs to the main tracking set
    if (newlyModifiedIds.size > 0) {
      setModifiedQuestionIds(prevSet => {
        const newSet = new Set(prevSet);
        newlyModifiedIds.forEach(id => newSet.add(id));
        return newSet;
      });
    }

    if (updatedCount > 0) {
      toast.success(`${updatedCount} réponse(s) correcte(s) ont été mises à jour localement. Cliquez sur 'Enregistrer' pour sauvegarder.`);
    } else {
      toast.info("Les réponses correctes correspondantes étaient déjà à jour.");
    }
  }, [setQuestions, setModifiedQuestionIds]);

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const validTypes = ['application/pdf', 'text/plain'];
    if (!validTypes.includes(file.type)) {
      toast.error('Format de fichier non supporté. Veuillez télécharger un fichier PDF ou texte.');
      return;
    }

    setUploading(true);
    setError('');
    try {
      const result = await api.uploadExamDocument(assignmentId, file);
      if (result.success) {
        toast.success('Document téléchargé et texte extrait avec succès.');
        await fetchAssignment(assignmentId); // Refresh assignment data
      } else {
        toast.error(result.message || 'Erreur lors du téléchargement du document.');
      }
    } catch (err) {
      console.error('Erreur lors du téléchargement du document:', err);
      toast.error(err.message || 'Erreur lors du téléchargement du document.');
      setError(err.message);
    } finally {
      setUploading(false);
      if (fileInputRef.current) fileInputRef.current.value = '';
    }
  };

  const handleUploadClick = () => fileInputRef.current?.click();

  return (
    <div className="space-y-6 p-4 sm:p-6 max-w-7xl mx-auto pb-20">
      {/* Breadcrumbs */}
      <Breadcrumb className="mb-6 text-muted-foreground">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/assignments" className="hover:text-foreground">Devoirs</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={`/grade/${assignmentId}`} className="hover:text-foreground">
              {assignment ? assignment.filename : `Devoir ${assignmentId}`}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage className="font-medium text-foreground">Gérer les questions</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      {/* Header */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
        <div>
          <h2 className="text-2xl font-bold text-foreground">Gérer les Questions et Réponses Correctes</h2>
          <p className="text-sm text-muted-foreground mt-1">
            Modifiez les réponses correctes et les points pour chaque question. Les changements seront utilisés par l'IA.
          </p>
        </div>
        <div className="flex flex-wrap gap-2">
          <input type="file" ref={fileInputRef} onChange={handleFileInputChange} accept=".pdf,.txt" style={{ display: 'none' }} />
          <Button variant="outline" onClick={handleUploadClick} disabled={uploading} className="gap-1.5" size="sm">
            {uploading ? <Spinner className="h-4 w-4" /> : <Upload className="h-4 w-4" />}
            {assignment?.exam_text ? 'Remplacer Doc Examen' : 'Télécharger Doc Examen'}
          </Button>
          <Button variant="outline" onClick={() => setImportModalOpen(true)} className="gap-1.5" size="sm">
            <Download className="h-4 w-4" />
            Importer Réponses
          </Button>
          <Button variant="outline" onClick={() => navigate(`/grade/${assignmentId}`)} className="gap-1.5" size="sm">
            <ArrowLeft className="h-4 w-4" />
            Retour
          </Button>
          <Button onClick={handleSave} disabled={saving || loading || modifiedQuestionIds.size === 0} className="gap-1.5" size="sm">
            {saving ? <Spinner className="h-4 w-4" /> : saveSuccess ? <Check className="h-4 w-4" /> : <Save className="h-4 w-4" />}
            {saving ? 'Enregistrement...' : saveSuccess ? 'Enregistré!' : `Enregistrer (${modifiedQuestionIds.size})`}
          </Button>
        </div>
      </div>

      {/* Assignment Info */}
      {assignment && (
        <Card className="shadow-sm overflow-hidden">
          <div className="bg-gradient-to-r from-violet-200 to-violet-200 p-6">
            <CardTitle className="text-lg text-foreground font-semibold">{assignment.filename}</CardTitle>
            <div className="flex flex-wrap gap-2 mt-1">
              <Badge variant="secondary">Créé le: {forceUtcThenConvertToMontreal(assignment.created_at)}</Badge>
              {assignment.google_course_id && <Badge variant="outline" className="text-blue-600 bg-blue-50 border-blue-200">Lié à Google Classroom</Badge>}
              {assignment.exam_text ? (
                <Badge variant="outline" className="text-green-600 bg-green-50 border-green-200">
                  Document d'examen présent ({Math.round(assignment.exam_text.length / 1000)}k car.)
                </Badge>
              ) : (
                 <Badge variant="outline" className="text-amber-600 bg-amber-50 border-amber-200">
                  Aucun document d'examen
                </Badge>
              )}
            </div>
          </div>
        </Card>
      )}
      
      {/* Info Alert */}
      <Alert className="bg-blue-50 border-blue-200 text-blue-800 dark:bg-blue-950/30 dark:text-blue-200 dark:border-blue-900/50">
        <Info className="h-5 w-5 text-blue-600 dark:text-blue-300 mt-0.5" />
        <div className="ml-3 flex-1">
            <AlertTitle className="font-semibold">Important : Réponses Correctes</AlertTitle>
            <AlertDescription className="mt-1 text-sm">
              Fournissez des réponses correctes précises avec explications élaborées pour chaque question. L'IA utilise ces informations comme référence principale pour évaluer les soumissions des élèves. Le mieux la réponse est décrite, plus l'IA est précise.
            </AlertDescription>
         </div>
      </Alert>

      {/* Error Display */}
      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Erreur</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {/* Loading State */}
      {loading ? (
        <div className="flex flex-col items-center justify-center py-12 px-6 border rounded-lg bg-card">
          <Spinner size={32} />
          <p className="mt-4 text-muted-foreground">Chargement des questions...</p>
        </div>
      ) : questions.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-12 px-6 border rounded-lg bg-card">
          <div className="rounded-full bg-muted p-3 mb-3">
            <HistoryIcon className="h-6 w-6 text-muted-foreground" />
          </div>
          <h3 className="font-medium text-lg">Aucune question trouvée</h3>
          <p className="text-sm text-muted-foreground mt-1 text-center">
            Impossible d'extraire les questions. Vérifiez si le devoir a été correctement importé ou téléversez un document d'examen.
          </p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <Table className="min-w-full">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[5%]">#</TableHead>
                <TableHead className="w-[25%]">Texte de la Question</TableHead>
                <TableHead className="w-[10%]">Type</TableHead>
                <TableHead className="w-[10%]">Points</TableHead>
                <TableHead className="w-[50%]">Réponse Correcte</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {questions.map((q, index) => (
                <TableRow key={q.id} className={cn("hover:bg-muted/50", isQuestionModified(q.id) && "bg-amber-50 dark:bg-amber-900/20")}>
                  <TableCell className="font-medium text-muted-foreground">{index + 1}</TableCell>
                  <TableCell className="whitespace-pre-wrap break-words">{q.question_text || '(Vide)'}</TableCell>
                  <TableCell>
                    <Badge variant="outline" className={cn(
                      q.question_type === 'mcq' && "border-blue-300 bg-blue-50 text-blue-800",
                      q.question_type === 'multiple_select' && "border-purple-300 bg-purple-50 text-purple-800",
                      q.question_type === 'text' && "border-gray-300 bg-gray-50 text-gray-800"
                    )}>
                      {q.question_type === 'mcq' ? 'Choix Multi.' :
                       q.question_type === 'multiple_select' ? 'Sélec. Multi.' :
                       'Texte'}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <Input
                      type="number"
                      value={q.points}
                      onChange={(e) => handleQuestionChange(q.id, 'points', parseFloat(e.target.value) || 0)}
                      min="0"
                      step="0.5"
                      className={cn("w-20 text-center", isQuestionModified(q.id) && "border-amber-500 ring-1 ring-amber-500")}
                    />
                  </TableCell>
                  <TableCell>
                    {q.question_type === 'mcq' && q.question_metadata?.choices ? (
                      <div className="space-y-2">
                        {q.question_metadata.choices.map((choice, idx) => (
                          <div key={idx} className="flex items-center gap-2">
                            <input
                              type="radio"
                              id={`mcq-${q.id}-${idx}`}
                              name={`mcq-${q.id}`}
                              checked={idx === q.question_metadata.correct_index}
                              onChange={() => handleMetadataChange(q.id, {
                                ...q.question_metadata,
                                correct_answer: choice,
                                correct_index: idx
                              })}
                              className="h-4 w-4 text-primary border-gray-300 focus:ring-primary"
                            />
                            <Label htmlFor={`mcq-${q.id}-${idx}`} className="text-sm font-normal">{choice}</Label>
                          </div>
                        ))}
                      </div>
                    ) : q.question_type === 'multiple_select' && q.question_metadata?.choices ? (
                      <div className="space-y-2">
                        {q.question_metadata.choices.map((choice, idx) => (
                          <div key={idx} className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              id={`ms-${q.id}-${idx}`}
                              checked={q.question_metadata.correct_indices?.includes(idx)}
                              onChange={(e) => {
                                const currentIndices = q.question_metadata.correct_indices || [];
                                const currentAnswers = q.question_metadata.correct_answers || [];
                                let newIndices, newAnswers;
                                if (e.target.checked) {
                                  newIndices = [...currentIndices, idx];
                                  newAnswers = [...currentAnswers, choice];
                                } else {
                                  newIndices = currentIndices.filter(i => i !== idx);
                                  newAnswers = currentAnswers.filter(a => a !== choice);
                                }
                                handleMetadataChange(q.id, {
                                  ...q.question_metadata,
                                  correct_answers: newAnswers,
                                  correct_indices: newIndices
                                });
                              }}
                              className="h-4 w-4 text-primary border-gray-300 focus:ring-primary rounded"
                            />
                            <Label htmlFor={`ms-${q.id}-${idx}`} className="text-sm font-normal">{choice}</Label>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Textarea
                        value={q.correct_answer || ''}
                        onChange={(e) => handleQuestionChange(q.id, 'correct_answer', e.target.value)}
                        placeholder="Entrez la réponse correcte ici..."
                        className={cn("min-h-[60px] resize-y", isQuestionModified(q.id) && "border-amber-500 ring-1 ring-amber-500")}
                        rows={Math.max(2, (q.correct_answer || '').split('\n').length)} // Auto-adjust rows
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      {/* Bottom Save Button */}
      {!loading && questions.length > 0 && (
        <div className="mt-8 flex justify-end">
          <Button onClick={handleSave} disabled={saving || loading || modifiedQuestionIds.size === 0} className="gap-2" size="lg">
            {saving ? <Spinner className="h-5 w-5" /> : saveSuccess ? <Check className="h-5 w-5" /> : <Save className="h-5 w-5" />}
            {saving ? 'Enregistrement...' : saveSuccess ? 'Enregistré!' : `Enregistrer les modifications (${modifiedQuestionIds.size})`}
          </Button>
        </div>
      )}

      {/* Import Correct Answers Modal */}
      <ImportCorrectAnswersModal
        open={importModalOpen}
        onOpenChange={setImportModalOpen}
        targetAssignmentId={assignmentId}
        onImportPreview={handleApplyImportedAnswers}
      />
    </div>
  );
}

export default AssignmentQuestionsPage;
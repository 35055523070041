import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Link } from 'react-router-dom'; // Import Link

function TermsOfService() {
  const lastUpdated = "28 Mars 2025"; // Update this date when the terms change

  return (
    <div className="container mx-auto max-w-4xl py-12 px-4 sm:px-6 lg:px-8">
      <Card className="bg-card text-card-foreground shadow-lg border border-border">
        <CardHeader className="border-b border-border">
          <CardTitle className="text-3xl font-bold text-center text-primary">
            Conditions d'Utilisation de Corrige
          </CardTitle>
          <p className="text-center text-sm text-muted-foreground mt-2">
            Dernière mise à jour : {lastUpdated}
          </p>
        </CardHeader>
        <CardContent className="pt-8 space-y-8 text-base leading-relaxed">
          <section>
            <h2 className="text-xl font-semibold mb-3">1. Introduction et Acceptation</h2>
            <p>
              Bienvenue sur Corrige ! Ces Conditions d'Utilisation (« Conditions ») régissent votre accès et utilisation de notre site web et de nos services de correction assistée par IA (« Services »). En accédant ou en utilisant nos Services, vous acceptez d'être lié par ces Conditions et par notre <Link to="/privacy-policy" className="text-primary hover:underline">Politique de Confidentialité</Link>. Si vous n'êtes pas d'accord, veuillez ne pas utiliser les Services.
            </p>
            <p className="mt-3">
              Nous pouvons modifier ces Conditions périodiquement. Votre utilisation continue après modification signifie que vous acceptez les nouvelles Conditions.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">2. Les Services Corrige</h2>
            <p>
              Corrige est une plateforme aidant les enseignants à corriger des devoirs via une assistance IA. Elle peut s'intégrer avec des services tiers comme Google Classroom, Forms, et Drive pour faciliter le flux de travail de correction.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">3. Votre Compte</h2>
            <p>
              L'utilisation de nos Services requiert la création d'un compte. Vous vous engagez à fournir des informations exactes et à les maintenir à jour. Vous êtes responsable de la sécurité de votre compte (identifiants, accès Google) et de toute activité s'y déroulant. Informez-nous immédiatement de toute utilisation non autorisée.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">4. Utilisation des Services</h2>
            <p className="mb-3">
              Vous vous engagez à utiliser les Services de manière légale et responsable, et à ne pas :
            </p>
            <ul className="list-disc pl-6 space-y-1">
              <li>Violer toute loi applicable (y compris les lois sur la vie privée et les données éducatives).</li>
              <li>Perturber ou surcharger nos systèmes.</li>
              <li>Tenter d'accéder sans autorisation aux comptes d'autrui.</li>
              <li>Transmettre du contenu malveillant.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">5. Intégrations Google</h2>
            <p>
              L'utilisation des intégrations Google nécessite votre autorisation via OAuth. Cela permet à Corrige d'accéder aux données nécessaires (décrites dans notre <Link to="/privacy-policy" className="text-primary hover:underline">Politique de Confidentialité</Link>) pour fournir les fonctionnalités liées à Google Classroom, Forms et Drive. Votre utilisation des services Google est soumise à leurs propres conditions.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">6. Frais et Paiements</h2>
            <p>
              Certaines fonctionnalités peuvent nécessiter un abonnement ou l'utilisation de crédits. Les détails sont disponibles sur notre page de tarification ou au moment de l'achat. Les paiements sont traités par Stripe. Sauf indication contraire ou exigence légale, les frais ne sont pas remboursables.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">7. Propriété Intellectuelle</h2>
            <p>
              <strong>Nos Services :</strong> Corrige détient tous les droits sur les Services et leur contenu (logiciel, design, marques). Nous vous accordons une licence limitée d'utilisation.
            </p>
            <p className="mt-3">
              <strong>Votre Contenu :</strong> Vous conservez la propriété de vos documents, corrigés, et réponses élèves (« Votre Contenu »). Vous nous accordez une licence limitée nécessaire pour fournir les Services (ex: traiter les réponses pour la correction IA, générer les retours, personnaliser les suggestions pour votre compte).
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">8. Confidentialité</h2>
            <p>
              La protection de vos données est essentielle. Nos pratiques sont détaillées dans notre <Link to="/privacy-policy" className="text-primary hover:underline">Politique de Confidentialité</Link>.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">9. Absence de Garantie et Limitation de Responsabilité</h2>
            <p>
              Les Services sont fournis « tels quels ». Nous ne garantissons pas qu'ils seront toujours disponibles, sécurisés, ou sans erreur.
            </p>
            <p className="mt-3">
              L'IA est un outil d'assistance. Les suggestions de notes et justifications peuvent contenir des erreurs. <strong>La responsabilité finale de l'évaluation et de la note attribuée vous incombe.</strong>
            </p>
            <p className="mt-3">
              Dans les limites permises par la loi, Corrige ne sera pas responsable des dommages indirects ou consécutifs découlant de l'utilisation des Services. Notre responsabilité totale est limitée aux montants que vous nous avez payés pour les Services concernés au cours des 12 mois précédents la réclamation (sauf pour vos obligations de paiement).
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">10. Résiliation</h2>
            <p>
              Vous pouvez cesser d'utiliser les Services et demander la suppression de votre compte à tout moment. Nous pouvons suspendre ou résilier votre accès si vous violez ces Conditions. En cas de résiliation, votre droit d'utiliser les Services cesse. La gestion de vos données suivra notre <Link to="/privacy-policy" className="text-primary hover:underline">Politique de Confidentialité</Link>.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">11. Droit Applicable</h2>
            <p>
              Ces Conditions sont régies par les lois de la Province de Québec et les lois fédérales du Canada applicables.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-3">12. Contact</h2>
            <p>
              Pour toute question sur ces Conditions, contactez-nous :
            </p>
            <p className="mt-2">
              <strong>Équipe Corrige</strong><br />
              Email : <a href="mailto:support@corrige.ca" className="text-primary hover:underline">support@corrige.ca</a>
            </p>
          </section>
        </CardContent>
      </Card>
    </div>
  );
}

export default TermsOfService; 
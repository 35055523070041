/*
<ai_context>
  GoogleClassroomSyncModal.jsx
  This is similar to GoogleFormImportModal but used for "Sync Grades" to Google Classroom.
  The user picks the course, assignment, then we call POST /classroom/courses/{course_id}/assignments/{coursework_id}/sync-grades
</ai_context>
*/

import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardTitle, CardContent } from "./ui/card";
import { Button } from "./ui/button";
import Spinner from "./Spinner";
import { toast } from "react-toastify";
import api from "../services/api";
import { API_BASE_URL, API_VERSION } from "../services/config";

/**
 * Props:
 * - assignment: { id, google_course_id, google_coursework_id }
 * - onClose: function
 * - onDone: function
 */
function GoogleClassroomSyncModal({ assignment, onClose, onDone }) {
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const [courses, setCourses] = useState([]);
  const [gAssignments, setGAssignments] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedAssignmentId, setSelectedAssignmentId] = useState("");

  const [loadingCourses, setLoadingCourses] = useState(false);
  const [loadingAssignments, setLoadingAssignments] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [error, setError] = useState("");

  const popupRef = useRef(null);
  const popupCheckIntervalRef = useRef(null);

  // Use stored IDs if available
  const hasStoredIds = !!(assignment?.google_course_id && assignment?.google_coursework_id);

  const ALLOWED_ORIGINS = [
    "http://localhost:3000",
    "http://127.0.0.1:3000",
    "http://localhost:5173",
    "http://127.0.0.1:5173",
    "http://localhost:8000",
    "http://127.0.0.1:8000",
    "https://api.corrige.ca",
    "https://corrige.ca",
    "https://app.corrige.ca"
  ];

  useEffect(() => {
    checkAuthorization();
  }, []);

  useEffect(() => {
    if (popupRef.current) {
      popupCheckIntervalRef.current = setInterval(() => {
        if (popupRef.current && popupRef.current.closed) {
          clearInterval(popupCheckIntervalRef.current);
          popupCheckIntervalRef.current = null;
          popupRef.current = null;
          if (!isAuthorized) {
            setIsAuthorizing(false);
          }
        }
      }, 800);
    }
    return () => {
      if (popupCheckIntervalRef.current) {
        clearInterval(popupCheckIntervalRef.current);
      }
    };
  }, [isAuthorized]);

  async function checkAuthorization() {
    const alreadyAuthorized = localStorage.getItem("googleAuthorized") === "true";
    if (alreadyAuthorized) {
      setIsAuthorized(true);
      if (!hasStoredIds) {
        loadCourses();
      }
      return;
    }
    try {
      const me = await api.getCurrentUser();
      if (me.google_access_token) {
        setIsAuthorized(true);
        localStorage.setItem("googleAuthorized", "true");
        if (!hasStoredIds) {
          loadCourses();
        }
      } else {
        openAuthPopup();
      }
    } catch (err) {
      console.error("Error checking authorization:", err);
      setError(err.message);
    }
  }

  function openAuthPopup() {
    if (isAuthorizing) return;
    setIsAuthorizing(true);

    api
      .getGoogleClassroomConnectUrl()
      .then((data) => {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No local auth token found. Please log in again.");
        }
        const urlWithState = `${data.auth_url}&state=${encodeURIComponent(token)}`;
        popupRef.current = window.open(
          urlWithState,
          "_blank",
          "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=600"
        );
        window.addEventListener("message", handleMessage);
      })
      .catch((err) => {
        console.error("Error opening auth popup:", err);
        setError(err.message);
        setIsAuthorizing(false);
      });
  }

  function handleMessage(event) {
    if (!ALLOWED_ORIGINS.includes(event.origin)) {
      return;
    }
    if (event.data === "google-oauth-success") {
      setIsAuthorized(true);
      setIsAuthorizing(false);
      localStorage.setItem("googleAuthorized", "true");
      if (popupRef.current) {
        popupRef.current.close();
      }
      popupRef.current = null;
      window.removeEventListener("message", handleMessage);
      if (!hasStoredIds) {
        loadCourses();
      }
    }
  }

  async function loadCourses() {
    setLoadingCourses(true);
    setError("");
    try {
      const data = await api.listCourses();
      setCourses(data);
    } catch (err) {
      console.error("Error listing courses:", err);
      setError(err.message);
    } finally {
      setLoadingCourses(false);
    }
  }

  async function handleSelectCourse(courseId) {
    setSelectedCourseId(courseId);
    setGAssignments([]);
    setSelectedAssignmentId("");
    if (!courseId) return;

    setLoadingAssignments(true);
    setError("");
    try {
      const data = await api.listAssignments(courseId);
      setGAssignments(data);
    } catch (err) {
      console.error("Error listing google assignments:", err);
      setError(err.message);
    } finally {
      setLoadingAssignments(false);
    }
  }

  async function handleSelectAssignment(gaid) {
    setSelectedAssignmentId(gaid);
  }

  async function handleSync() {
    if (!assignment?.id) {
      toast.error("Aucun devoir local trouvé.");
      return;
    }

    // Use stored IDs if available, otherwise use selected ones
    const courseId = hasStoredIds ? assignment.google_course_id : selectedCourseId;
    const courseworkId = hasStoredIds ? assignment.google_coursework_id : selectedAssignmentId;

    if (!courseId || !courseworkId) {
      toast.error("Veuillez sélectionner un cours et un devoir google classroom.");
      return;
    }

    setSyncing(true);
    setError("");

    try {
      const url = `${API_BASE_URL}${API_VERSION}/classroom/courses/${courseId}/assignments/${courseworkId}/sync-grades`;
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ assignment_id: assignment.id })
      });
      const txt = await resp.text();
      if (!resp.ok) {
        throw new Error(txt || "Échec de la synchronisation des notes.");
      }
      const data = JSON.parse(txt);
      toast.success(data.message || "Notes synchronisées avec succès.");
      if (onDone) onDone();
    } catch (err) {
      console.error("Error syncing grades:", err);
      toast.error(err.message || "Échec de la synchronisation des notes.");
      setError(err.message);
    } finally {
      setSyncing(false);
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="bg-white w-full max-w-xl p-4 rounded shadow-md">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-semibold">Synchroniser les notes vers Google Classroom</h2>
          <Button variant="ghost" onClick={onClose}>Fermer</Button>
        </div>

        {error && (
          <div className="mb-2 bg-destructive/20 text-destructive p-2 rounded text-sm">
            {error}
          </div>
        )}

        {!isAuthorized && isAuthorizing && (
          <div className="py-4 flex items-center justify-center">
            <Spinner />
            <span className="ml-2 text-sm">Autorisation avec Google...</span>
          </div>
        )}

        {isAuthorized && (
          <>
            {/* Only show course/assignment selection if we don't have stored IDs */}
            {!hasStoredIds && (
              <Card className="mb-4">
                <CardHeader>
                  <CardTitle>Sélectionner le cours et le devoir</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col sm:flex-row gap-4">
                    <div className="flex-1">
                      <label className="block text-sm font-medium">
                        Cours
                        {loadingCourses && <Spinner />}
                      </label>
                      <select
                        value={selectedCourseId}
                        onChange={(e) => handleSelectCourse(e.target.value)}
                        className="mt-1 border border-gray-300 rounded px-2 py-1 w-full"
                      >
                        <option value="">-- Choisir un cours --</option>
                        {courses.map((c) => (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex-1">
                      <label className="block text-sm font-medium">
                        Devoir
                        {loadingAssignments && <Spinner />}
                      </label>
                      <select
                        value={selectedAssignmentId}
                        onChange={(e) => handleSelectAssignment(e.target.value)}
                        className="mt-1 border border-gray-300 rounded px-2 py-1 w-full"
                      >
                        <option value="">-- Choisir un devoir --</option>
                        {gAssignments.map((a) => (
                          <option key={a.id} value={a.id}>
                            {a.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}

            <div className="mt-6 flex justify-end">
              <Button
                onClick={handleSync}
                disabled={syncing || (!hasStoredIds && !selectedAssignmentId)}
              >
                {syncing ? "Synchronisation en cours..." : "Synchroniser les notes"}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default GoogleClassroomSyncModal;
/*
<ai_context>
NavBar.jsx
We refactor to use shadcn <Button>. 
We keep some minimal layout classes from tailwind for spacing.
We convert user-facing text to French.
</ai_context>
*/

import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserContext';
import * as Sentry from "@sentry/react";
import { Button } from './ui/button';
import { ThemeToggle } from './theme-toggle';

function NavBar() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const { userCredits, refreshCredits } = useUser();

  useEffect(() => {
    if (token) {
      refreshCredits();
    }
  }, [token, refreshCredits]);

  const handleLogout = () => {
    // Remove token
    localStorage.removeItem('token');
    localStorage.removeItem('googleAuthorized');
    
    // Clear Sentry user context on logout
    Sentry.setUser(null);
    
    navigate('/login');
  };

  return (
    <nav className="w-full border-b bg-card">
      <div className="mx-auto max-w-7xl px-4">
        <div className="flex items-center justify-between h-16">

          {/* Left side: Brand/Logo */}
          <div className="flex items-center">
            <Link to="/assignments" className="text-xl font-bold flex items-center">
            <img src="/group-1.svg" width={30}></img>
            <span>orrige
            </span>
            </Link>
          </div>
          
          {/* Right side: Navigation Links */}
          <div className="flex space-x-4 items-center">
            <ThemeToggle />
            {token ? (
              <>
                {/* Main navigation */}
                <Link to="/assignments">
                  <Button variant="ghost">Devoirs</Button>
                </Link>
                <Link to="/grading-memory">
                  <Button variant="ghost">Mémoire de Correction</Button>
                </Link>
                
                {/* Credits display */}
                <div className="rounded-full bg-accent px-3 py-1 text-sm text-accent-foreground">
                  <span className="font-medium">{userCredits !== null ? userCredits : '...'}</span>
                  <span className="ml-1">crédits</span>
                </div>
                <Button variant="secondary" onClick={handleLogout}>
                  Déconnexion
                </Button>
              </>
            ) : (
              <>
                <Link to="/login" className="text-sm font-medium hover:underline">
                  Connexion
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
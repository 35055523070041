import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from './ui/dialog';
import { Button } from './ui/button';
import { Textarea } from './ui/textarea';
import { Label } from './ui/label';
import { toast } from 'react-toastify';
import api from '../services/api';

const GradeLogModal = ({ 
  open, 
  onClose, 
  assignmentId, 
  studentEmail, 
  answerId, 
  grade, 
  questionId,
  questionNumber,
  subIndex,
  answerIndex,
  onUpdateComplete,
  // Additional information to display
  question,
  answer,
  correct_answer,
  old_grade
}) => {
  const [explanation, setExplanation] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentStage, setCurrentStage] = useState('explanation'); // 'explanation' or 'regrade'

  const handleConfirmExplanation = async () => {
    setLoading(true);
    try {
      // Update the answer with both the grade and explanation in a single call
      const updatedAnswer = await api.updateAnswer(assignmentId, studentEmail, answerId, {
        grade: grade, 
        explanation: explanation || undefined, // Only include if not empty
        status: 'locked' // Lock it after manual grading
      });
      
      // Notify parent component about the update so UI can be refreshed
      if (onUpdateComplete) {
        onUpdateComplete(subIndex, answerIndex, updatedAnswer);
      }
      
      toast.success(explanation ? 'Note et explication enregistrées' : 'Note enregistrée');
      setCurrentStage('regrade');
      setLoading(false);
    } catch (err) {
      toast.error(`Erreur: ${err.message}`);
      setLoading(false);
    }
  };

  const handleRegrade = async () => {
    setLoading(true);
    try {
      console.log("Regrading with questionId:", questionId, "questionNumber:", questionNumber);
      
      if (!questionId) {
        // Get all questions for this assignment to find the question ID
        const questions = await api.getAssignmentQuestions(assignmentId);
        console.log("Fetched questions:", questions);
        
        // Find the right question by question number
        const question = questions.find(q => q.question_number === String(questionNumber));
        
        if (!question) {
          toast.error("Impossible de re-corriger: Question introuvable");
          setLoading(false);
          return;
        }
        
        console.log("Found question:", question);
        
        // Use the found question's ID
        const fetchedQuestionId = question.id;
        const result = await api.regradeQuestion(assignmentId, fetchedQuestionId);
        toast.success(result.message || 'La re-correction a été lancée');
        onClose();
      } else {
        // Use the provided question ID
        const result = await api.regradeQuestion(assignmentId, questionId);
        toast.success(result.message || 'La re-correction a été lancée');
        onClose();
      }
    } catch (err) {
      console.error("Regrade error:", err);
      toast.error(`Erreur de re-correction: ${err.message || 'Une erreur est survenue'}`);
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {currentStage === 'explanation' 
              ? 'Explication de votre correction' 
              : 'Re-corriger les autres réponses?'}
          </DialogTitle>
        </DialogHeader>

        {currentStage === 'explanation' && (
          <div className="space-y-4">
            {/* Information about the question and answers */}
            <div className="rounded-md border border-border p-4 space-y-3 bg-muted/10">
              <div>
                <h3 className="font-medium text-sm">Question {questionNumber}</h3>
                <p className="mt-1">{question}</p>
              </div>
              
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="font-medium text-sm text-green-600 dark:text-green-400">Réponse correcte</h3>
                  <p className="mt-1 text-sm whitespace-pre-wrap">{correct_answer || '(Aucune réponse correcte définie)'}</p>
                </div>
                
                <div>
                  <h3 className="font-medium text-sm text-blue-600 dark:text-blue-400">Réponse de l'élève</h3>
                  <p className="mt-1 text-sm whitespace-pre-wrap">{answer || '(Aucune réponse)'}</p>
                </div>
              </div>
              
              <div className="border-t border-border pt-3">
                <h3 className="font-medium text-sm">Changement de note</h3>
                <div className="flex items-center gap-2 mt-1">
                  <span className="text-muted-foreground line-through">{old_grade}</span>
                  <span className="text-lg">→</span>
                  <span className="font-medium text-primary">{grade}</span>
                </div>
              </div>
            </div>
            
            <p className="text-sm text-muted-foreground">
              Vous pouvez donner une explication pour cette correction. Cela aidera l'IA à comprendre votre logique pour les futures corrections.
            </p>
            <div className="space-y-2">
              <Label htmlFor="explanation">Explication (optionnel)</Label>
              <Textarea
                id="explanation"
                value={explanation}
                onChange={(e) => setExplanation(e.target.value)}
                placeholder="Expliquez pourquoi vous avez attribué cette note..."
                className="min-h-[100px]"
              />
            </div>
            <DialogFooter className="flex justify-end">
              <Button onClick={handleConfirmExplanation} disabled={loading}>
                {loading ? 'Enregistrement...' : 'Confirmer'}
              </Button>
            </DialogFooter>
          </div>
        )}

        {currentStage === 'regrade' && (
          <div className="space-y-4">
            {/* Keep showing question summary in regrade mode too */}
            <div className="rounded-md border border-border p-3 bg-muted/10">
              <div>
                <h3 className="font-medium text-sm">Question {questionNumber}</h3>
                <p className="mt-1 text-sm">{question}</p>
              </div>
            </div>
          
            <p className="mt-2">
              Voulez-vous que l'IA de Corrige utilise votre correction pour re-corriger toutes les autres réponses à cette question?
            </p>
            <p className="text-sm text-muted-foreground">
              Cela va re-corriger toutes les réponses qui ne sont pas encore verrouillées.
            </p>
            <DialogFooter className="flex justify-end space-x-2">
              <Button variant="outline" onClick={handleClose} disabled={loading}>
                Non
              </Button>
              <Button onClick={handleRegrade} disabled={loading}>
                {loading ? 'Lancement...' : 'Oui, re-corriger'}
              </Button>
            </DialogFooter>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GradeLogModal;
/*
<ai_context>
  dateUtils.js
  A helper function for forcing UTC -> Montreal time display
</ai_context>
*/

export function forceUtcThenConvertToMontreal(dateString) {
  // Some DB rows might come as "2025-01-30T21:23:31" with no 'Z'. 
  // We'll append Z to ensure it's parsed as UTC, if it doesn't already include Z.
  const isoString = dateString.endsWith('Z') ? dateString : dateString + 'Z';

  // Parse as UTC
  const dateObj = new Date(isoString);

  // Format as Montreal time with date and HH:mm format
  return dateObj.toLocaleString('en-CA', {
    timeZone: 'America/Toronto',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}
/*
<ai_context>
App.jsx
We replace references from /solutions => /assignments
We rename SolutionsPage => AssignmentsPage, etc.
We rename route from "/grade/:solutionId" => "/grade/:assignmentId"
And from "/grade/:solutionId/questions" => "/grade/:assignmentId/questions"
</ai_context>
*/

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from "@sentry/react";
import 'react-toastify/dist/ReactToastify.css';
import { store } from './store';

import NavBar from './components/NavBar';
import ProtectedRoute from './components/routing/ProtectedRoute';
import PublicRoute from './components/routing/PublicRoute';
import { ThemeProvider } from './components/theme-provider';

// Pages
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import AssignmentsPage from './pages/AssignmentsPage';
import AssignmentDetailPage from './pages/AssignmentDetailPage';
import AssignmentQuestionsPage from './pages/AssignmentQuestionsPage';
import GradingMemoryPage from './pages/GradingMemory';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';

const App = () => {
  return (
    <ThemeProvider defaultTheme="light">
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <Provider store={store}>
          <Router>
            <Sentry.ErrorBoundary fallback={<p>Une erreur est survenue.</p>}>
              <div className="min-h-screen flex flex-col bg-background">
                <NavBar />
                <main className="flex-grow">
                  <Routes>
                    {/* Public routes */}
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-of-service" element={<TermsOfService />} />

                    {/* Public route: Login */}
                    <Route
                      path="/login"
                      element={
                        <div className="container mx-auto px-4 py-8">
                          <PublicRoute>
                            <LoginPage />
                          </PublicRoute>
                        </div>
                      }
                    />

                    {/* Protected route: Assignments list (home) */}
                    <Route
                      path="/assignments"
                      element={
                        <div className="container mx-auto px-4 py-8">
                          <ProtectedRoute>
                            <AssignmentsPage />
                          </ProtectedRoute>
                        </div>
                      }
                    />

                    {/* Protected route: Single assignment detail w/ submissions */}
                    <Route
                      path="/grade/:assignmentId"
                      element={
                        <div className="container mx-auto px-4 py-8">
                          <ProtectedRoute>
                            <AssignmentDetailPage />
                          </ProtectedRoute>
                        </div>
                      }
                    />

                    {/* Protected route: manage assignment's questions */}
                    <Route
                      path="/grade/:assignmentId/questions"
                      element={
                        <div className="container mx-auto px-4 py-8">
                          <ProtectedRoute>
                            <AssignmentQuestionsPage />
                          </ProtectedRoute>
                        </div>
                      }
                    />

                    {/* Protected route: Grading memory page */}
                    <Route
                      path="/grading-memory"
                      element={
                        <div className="container mx-auto px-4 py-8">
                          <ProtectedRoute>
                            <GradingMemoryPage />
                          </ProtectedRoute>
                        </div>
                      }
                    />
                  </Routes>
                </main>
                <ToastContainer position="bottom-right" />
              </div>
            </Sentry.ErrorBoundary>
          </Router>
        </Provider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
};

export default App;
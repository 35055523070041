/*
<ai_context>
  table.jsx
  A simple shadcn-like table component for styling.
</ai_context>
*/

import React from "react"
import { cn } from "../../lib/utils"

export function Table({ className, ...props }) {
  return (
    <table
      className={cn("w-full border-collapse bg-card text-sm", className)}
      {...props}
    />
  )
}

export function TableHeader({ className, ...props }) {
  return (
    <thead className={cn("[&_tr]:border-b", className)} {...props} />
  )
}

export function TableBody({ className, ...props }) {
  return <tbody className={cn("[&_tr:last-child]:border-0", className)} {...props} />
}

export function TableRow({ className, ...props }) {
  return (
    <tr
      className={cn("border-b transition-colors hover:bg-accent/50 data-[state=selected]:bg-accent", className)}
      {...props}
    />
  )
}

export function TableHead({ className, ...props }) {
  return (
    <th
      className={cn("px-4 py-2 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0", className)}
      {...props}
    />
  )
}

export function TableCell({ className, ...props }) {
  return (
    <td
      className={cn("p-4 align-middle [&:has([role=checkbox])]:pr-0", className)}
      {...props}
    />
  )
}
import * as React from "react"
import { ChevronRight, MoreHorizontal } from "lucide-react"
import { cn } from "../../lib/utils"

const Breadcrumb = React.forwardRef(({ className, ...props }, ref) => (
  <nav
    ref={ref}
    className={cn("flex items-center text-sm font-medium", className)}
    aria-label="Breadcrumb"
    {...props}
  />
))
Breadcrumb.displayName = "Breadcrumb"

const BreadcrumbList = React.forwardRef(({ className, ...props }, ref) => (
  <ol ref={ref} className={cn("flex flex-wrap items-center gap-1.5", className)} {...props} />
))
BreadcrumbList.displayName = "BreadcrumbList"

const BreadcrumbItem = React.forwardRef(({ className, ...props }, ref) => (
  <li
    ref={ref}
    className={cn("inline-flex items-center gap-1.5", className)}
    {...props}
  />
))
BreadcrumbItem.displayName = "BreadcrumbItem"

const BreadcrumbSeparator = React.forwardRef(({ className, children, ...props }, ref) => (
  <li
    ref={ref}
    className={cn("opacity-60", className)}
    aria-hidden="true"
    {...props}
  >
    {children ?? <ChevronRight className="h-3 w-3" />}
  </li>
))
BreadcrumbSeparator.displayName = "BreadcrumbSeparator"

const BreadcrumbEllipsis = React.forwardRef(({ className, ...props }, ref) => (
  <span
    ref={ref}
    className={cn("flex h-9 w-9 items-center justify-center", className)}
    {...props}
  >
    <MoreHorizontal className="h-4 w-4" />
    <span className="sr-only">More pages</span>
  </span>
))
BreadcrumbEllipsis.displayName = "BreadcrumbEllipsis"

const BreadcrumbLink = React.forwardRef(({ className, asChild, ...props }, ref) => (
  <a
    ref={ref}
    className={cn("text-muted-foreground hover:text-foreground transition-colors", className)}
    {...props}
  />
))
BreadcrumbLink.displayName = "BreadcrumbLink"

const BreadcrumbPage = React.forwardRef(({ className, ...props }, ref) => (
  <span
    ref={ref}
    className={cn("text-foreground font-semibold", className)}
    aria-current="page"
    {...props}
  />
))
BreadcrumbPage.displayName = "BreadcrumbPage"

export {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbSeparator,
  BreadcrumbEllipsis,
  BreadcrumbLink,
  BreadcrumbPage
} 
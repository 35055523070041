/*
<ai_context>
AssignmentsPage.jsx
We add a button "Créer un devoir dans Google Classroom (brouillon)" that opens GoogleAssignmentCreatorModal.
</ai_context>
*/

import React, { useEffect, useState } from "react";
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../services/api";
import { Button } from "../components/ui/button";
import { Card, CardHeader, CardTitle, CardContent } from "../components/ui/card";
import GoogleFormAssignmentCreatorModal from "../components/GoogleFormAssignmentCreatorModal";
import { forceUtcThenConvertToMontreal } from '../lib/dateUtils';

function AssignmentsPage() {
  const { userCredits, refreshCredits } = useUser();
  const navigate = useNavigate();

  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [showAddAssignmentModal, setShowAddAssignmentModal] = useState(false);
  const [showFormImportModal, setShowFormImportModal] = useState(false);

  const fetchAssignments = async () => {
    setLoading(true);
    setError("");
    try {
      const data = await api.getAssignments();
      setAssignments(data);
    } catch (err) {
      console.error("Error fetching assignments:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignments();
    refreshCredits();
  }, [refreshCredits]);

  const handleCardClick = (assignmentId) => {
    navigate(`/grade/${assignmentId}`);
  };

  const handleImportSuccess = async () => {
    setShowFormImportModal(false);
    setShowAddAssignmentModal(false);
    await fetchAssignments();
    toast.success("Devoir importé depuis Google Form avec succès !");
    refreshCredits();
  };

  return (
    <div className="container mx-auto py-8 space-y-6">
      {error && (
        <div className="bg-destructive/20 text-destructive p-3 rounded">
          <strong>Erreur : </strong>
          <span>{error}</span>
        </div>
      )}

      <div className="flex justify-between items-center pb-6">
        <h1 className="text-5xl font-bold">Vos devoirs</h1>
      </div>

      {loading && <p>Chargement des devoirs...</p>}

      {!loading && assignments.length === 0 && (
        <p className="text-muted-foreground">Aucun devoir pour le moment. Ajoutez-en un ci-dessous.</p>
      )}

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {/* "Add new assignment" card */}
        <Card
          className="hover:bg-accent/20 cursor-pointer flex items-center justify-center"
          onClick={() => setShowAddAssignmentModal(true)}
        >

            <CardTitle className="text-center text-3xl font-bold">+</CardTitle>
      
        </Card>

        {[...assignments]
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
          .map((asn) => (
            <Card
              key={asn.id}
              className="cursor-pointer hover:bg-accent/20"
              onClick={() => handleCardClick(asn.id)}
            >
              <CardHeader>
                <CardTitle className="font-semibold">
                  {asn.filename}
                </CardTitle>
              </CardHeader>
              <CardContent className="min-h-[120px]">
                <p className="text-xs text-muted-foreground">
                  {forceUtcThenConvertToMontreal(asn.created_at)}
                </p>
              </CardContent>
            </Card>
          ))}
      </div>

      {/* Add new assignment modal (Doc-based or Google Form) */}
      {showAddAssignmentModal && (
        <div 
          className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowAddAssignmentModal(false);
            }
          }}
        >
          <div className="bg-white p-4 rounded shadow-md w-full max-w-md relative">
            <button
              onClick={() => setShowAddAssignmentModal(false)}
              className="absolute top-2 right-2 text-sm text-gray-600 hover:text-gray-900"
            >
              X
            </button>

            <div className="space-y-6 pt-6">
              <h2 className="text-lg font-semibold text-center">Ajouter un nouveau devoir</h2>
              <div className="flex flex-col space-y-4">
                <Button
                  variant="outline"
                  onClick={() => setShowFormImportModal(true)}
                >
                  Importer depuis Google Forms
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Google Form Import Modal */}
      {showFormImportModal && (
        <GoogleFormAssignmentCreatorModal
          onClose={() => setShowFormImportModal(false)}
          onSuccess={handleImportSuccess}
        />
      )}
    </div>
  );
}

export default AssignmentsPage;
import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import App from './App.jsx';
import { UserProvider } from './context/UserContext';
import * as Sentry from "@sentry/react";

// Initialize Sentry
if (import.meta.env.VITE_SENTRY_DSN) {
  console.log(`Initializing Sentry for frontend environment: ${import.meta.env.VITE_SENTRY_ENVIRONMENT}, release: ${import.meta.env.VITE_SENTRY_RELEASE}`);
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      // Default integrations + Browser Tracing + Replay
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // Additional Replay configuration options can go here
        maskAllText: false, // Set to true to mask all text
        blockAllMedia: false, // Set to true to block all media
      }),
    ],
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development',
    release: import.meta.env.VITE_SENTRY_RELEASE || 'local-dev',
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of transactions, adjust in production
    // Session Replay
    replaysSessionSampleRate: 0.1, // Capture 10% of sessions, adjust as needed
    replaysOnErrorSampleRate: 1.0, // Capture 100% of sessions with errors
  });
} else {
  console.log("Sentry DSN not found, skipping Sentry initialization for frontend.");
}

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>
); 
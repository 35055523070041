/*
<ai_context>
LoginPage.jsx
We update the post-login redirect from /grade to /assignments.
We convert user-facing text to French.
</ai_context>
*/

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import * as Sentry from "@sentry/react";
import { API_BASE_URL, API_VERSION } from '../services/config';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const data = await api.loginUser(email, password);
      localStorage.setItem('token', data.access_token);
      
      // Set user context in Sentry
      try {
        const userDetails = await api.getCurrentUser();
        Sentry.setUser({
          id: userDetails.id.toString(),
          email: userDetails.email,
          username: userDetails.full_name || userDetails.email,
        });
      } catch (userError) {
        console.error("Failed to set Sentry user context:", userError);
      }
      
      navigate('/assignments');
    } catch (err) {
      console.error('Login error:', err);
      setError(err.response?.data?.detail || err.message || "Une erreur s'est produite lors de la connexion");
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      if (!credentialResponse.credential) {
        throw new Error('Aucun identifiant reçu de Google');
      }
      const requestUrl = `${API_BASE_URL}${API_VERSION}/auth/google/token-verify`;
      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: credentialResponse.credential }),
      });
      const responseText = await response.text();
      if (!response.ok) {
        throw new Error(`Erreur HTTP ! statut: ${response.status}, corps: ${responseText}`);
      }
      const data = JSON.parse(responseText);
      if (!data.access_token) {
        throw new Error("Aucun jeton d'accès reçu du serveur");
      }
      localStorage.setItem('token', data.access_token);
      
      // Set user context in Sentry
      try {
        const userDetails = await api.getCurrentUser();
        Sentry.setUser({
          id: userDetails.id.toString(),
          email: userDetails.email,
          username: userDetails.full_name || userDetails.email,
        });
      } catch (userError) {
        console.error("Failed to set Sentry user context:", userError);
      }
      
      toast.success('Connexion Google réussie!');
      navigate('/assignments');
    } catch (err) {
      console.error('Google login error:', err);
      setError(err.message || 'Échec de la connexion via Google. Veuillez réessayer.');
      toast.error('La connexion Google a échoué. Veuillez réessayer.');
    }
  };

  const handleGoogleFailure = (error) => {
    console.error('Google Sign-In error:', error);
    setError('La connexion Google a échoué. Veuillez réessayer.');
    toast.error('La connexion Google a échoué. Veuillez réessayer.');
  };

  return (
    <div className="max-w-sm mx-auto mt-10 p-6 rounded border bg-card text-card-foreground">
      <h1 className="text-2xl font-bold mb-4">Se connecter à Corrige</h1>
      <div className="mb-4">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
          useOneTap
          theme="outline"
          size="large"
          width="100%"
          text="signin_with"
          shape="rectangular"
        />
      </div>
      <div className="flex items-center mb-4">
        <hr className="flex-grow border-gray-300" />
        <span className="mx-2 text-sm text-muted-foreground">ou</span>
        <hr className="flex-grow border-gray-300" />
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <Label>Adresse courriel</Label>
          <Input
            type="email"
            className="mt-1"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <Label>Mot de passe</Label>
          <Input
            type="password"
            className="mt-1"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <Button
          type="submit"
          className="w-full"
        >
          Connexion
        </Button>
      </form>
      {error && (
        <div className="mt-4 text-destructive font-medium">
          {error}
        </div>
      )}
      <div className="mt-4 flex justify-between text-sm">
        <a href="/forgot-password" className="underline hover:text-primary">
          Mot de passe oublié?
        </a>
      </div>
    </div>
  );
}

export default LoginPage;
// theme-provider.jsx
import { createContext, useContext, useEffect, useState } from "react";

const ThemeProviderContext = createContext();

export function ThemeProvider({ children, defaultTheme = "light" }) {
  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    // Instead of document.documentElement, target our #root .corrige-app
    const rootElement = document.getElementById("root");
    if (!rootElement) return;

    // Remove old classes
    rootElement.classList.remove("light", "dark");

    // If you have a "system" option, handle that similarly
    if (theme === "system") {
      const systemPrefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
      rootElement.classList.add(systemPrefersDark ? "dark" : "light");
    } else {
      // Add the newly selected theme class
      rootElement.classList.add(theme);
    }
  }, [theme]);

  const value = {
    theme,
    setTheme,
  };

  return (
    <ThemeProviderContext.Provider value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
